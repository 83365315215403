import { priceFormat } from "@dvsproj/ipat-core/formatter"

const CELL_SEPARATOR = ";"

class BaseCsvExport {
  _lines = []

  static _priceRounding(price) {
    return priceFormat(null, price)
  }

  constructor(intl, products) {
    this._intl = intl
    this._products = products
  }

  _formatField(field) {
    return typeof field === "string"
        ? // @ts-ignore
        `"${field.replaceAll('"', '""')}"`
        : `"${field}"`
  }

  _formatPrice(price) {
    return priceFormat(this._intl, price)
  }

  getToBlob() {
    const blob = new Blob(
        [
          "\uFEFF" +
          this._lines.map(line => line.join(CELL_SEPARATOR)).join("\r\n")
        ],
        {
          type: "data:text/csv;charset=utf-8"
        }
    )

    return blob
  }
}

export { BaseCsvExport }
