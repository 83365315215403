import React from "react";

function ToolsBlock({
  children,
  label = null,
  hasHeaderLine = true,
  hasLine = false,
}) {
  return (
    <React.Fragment>
      <div className="tools-block">
        {hasHeaderLine ? <div className="tools-line"></div> : ""}
        {label ? <label>{label}</label> : ""}
        <div className="tools-block-elements">{children}</div>
        {hasLine ? <div className="tools-line"></div> : ""}
      </div>
    </React.Fragment>
  );
}

export default ToolsBlock;
