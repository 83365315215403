import { action, extendObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";

import planPipelineFactory from "../types/planPipelineFactory";
import { calculateAngleByPoints } from "@dvsproj/ipat-core/geometryUtils";
import { getIsolatePipelineCircuitsWithFlowAndDrops } from "@dvsproj/ipat-core/pipelineUtils";

import {
  sprinklerLineErrors,
  driplineLineErrors,
  pressureTubingLineErrors,
} from "../../utils/pipelineErrorsUtils";
import { throttle } from "lodash";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (plan, settingsState) => {
  const pipelineFactory = planPipelineFactory(plan, settingsState);

  const innerState = observable({
    pipelines: undefined,
    circuitsCalculatedAt: undefined,
    pipesModifiedAt: undefined,
  });

  extendObservable(plan, {
    cleanupPipelineCircuits() {
      innerState.pipelines = undefined;
      innerState.circuitsCalculatedAt = undefined;
      innerState.pipesModifiedAt = undefined;
    },
    recalculatePipelineCircuits: throttle(
      action(() => {
        const circuits = getIsolatePipelineCircuitsWithFlowAndDrops(plan);
        innerState.pipelines = circuits.map((c) => pipelineFactory(c));
        const drops = circuits.reduce(
          (acc, val) => acc.concat(val?.drops ?? []),
          []
        );
        plan.updateElementsDrop(drops);
        innerState.circuitsCalculatedAt = new Date();
      }),
      100
    ),
    updatePipesModifiedAt: action(() => {
      innerState.pipesModifiedAt = new Date();
    }),
    get pipelines() {
      return innerState.pipelines;
    },
    get pipelinesCalculatedMs() {
      return innerState.circuitsCalculatedAt;
    },
    get pipesModifiedAt() {
      return innerState.pipesModifiedAt;
    },
  });

  extendObservable(plan, {
    get pipelineColors() {
      return !innerState.pipelines
        ? null
        : [
            ...innerState.pipelines
              .filter((p) => !p.isStartPoint)
              .map((p) => p.color),
            "#9EA1A2",
          ].filter((v, i, a) => a.indexOf(v) === i);
    },
    addPipePoint: action((data, lineId) => {
      const point = plan.addElementToStorage({
        ...data,
        type: "pipeline-point",
      });
      const line = plan.pipes.find((l) => l.id === lineId);
      if (line != null) {
        plan.addElementToStorage({
          type: "pipe",
          start: point.id,
          end: line.end,
          color: line.color,
        });
        line.end = point.id;
      }
      plan.pipesModified = true;
      plan.updatePipesModifiedAt();
      return point;
    }),
    addPipe: action(({ start, end, color }) => {
      const pipes = plan.pipes.filter((pipe) => {
        return (
          (pipe.start === start && pipe.end === end) ||
          (pipe.end === start && pipe.start === end)
        );
      });
      if (pipes && pipes.length > 0) {
        return;
      }
      const result = plan.addElementToStorage({
        type: "pipe",
        start,
        end,
        color,
      });
      plan.pipesModified = true;
      plan.updatePipesModifiedAt();
      return result;
    }),
    addPipeline: (points, paths, key) => {
      //sanityCheck
      const filteredPoints = points
        .map((curr, i) => {
          const count =
            curr.type !== "sprinkler" &&
            curr.type !== "system-element" &&
            curr.type !== "rzws" &&
            curr.type !== "raised-bed"
              ? points.filter(
                  (p) =>
                    p.parentId === curr.parentId &&
                    p.x === curr.x &&
                    p.y === curr.y
                ).length
              : 1;
          let angle = 0;
          if (i > 0 && i < points.length - 1) {
            const prevPoint = points[i - 1];
            const nextPoint = points[i + 1];
            angle = calculateAngleByPoints(prevPoint, curr, nextPoint);
          }

          return {
            ...curr,
            countInCircuit: count,
            angle,
          };
        })
        .filter(
          ({ type, pointType, angle, countInCircuit }) =>
            countInCircuit > 1 ||
            type != null ||
            pointType != null ||
            angle < 175
        );
      const createdPoints = [];

      const createPoint = (
        { x, y, parentId, pointType },
        id,
        type = "l-point"
      ) => {
        const keyPrefix = "pipe-point-" + key + "-";

        let point =
          parentId != null
            ? plan.pipePoints.find((e) => e.parentId === parentId)
            : null;
        if (point == null) {
          point = plan.addElementToStorage({
            type: "pipeline-point",
            pointType: pointType || type,
            id: keyPrefix + id,
            x,
            y,
            parentId,
          });
        }
        createdPoints.push(point);
        return point;
      };

      for (let i = 0; i < filteredPoints.length - 1; i++) {
        let [start, end] = [i, i + 1].map((index) => {
          const id = uuid();
          return (
            createdPoints.find(
              ({ parentId }) =>
                parentId != null && filteredPoints[index].parentId === parentId
            ) ||
            createPoint(
              filteredPoints[index],
              id,
              index === 0 ? "start-point" : "l-point"
            )
          );
        });
        plan.addElementToStorage({
          type: "pipe",
          start: start.id,
          end: end.id,
        });
      }
      //init
      plan.updatePipesModifiedAt();
    },
    addTubingTree: (branches, paths, key) => {
      if (branches == null || branches.length === 0) {
        return;
      }

      branches.sort((a, b) => a.level - b.level);
      let branchPoints = [];
      let createdPoints = [];
      const createPoint = (
        { x, y, parentId, pointType, id: sid },
        id,
        type = "l-point"
      ) => {
        const keyPrefix = "pipe-point-" + key + "-";

        let point =
          parentId != null
            ? plan.pipePoints.find((e) => {
                const parentIsSystem = parentId.indexOf("system-element") >= 0;
                //generates pipelines connect to system-elements directly
                //but for step 4 in UI we need connect to pipe-point
                if (parentIsSystem) {
                  return e.parentId === parentId;
                }

                return e.id === parentId;
              })
            : null;
        if (point == null) {
          point = plan.addElementToStorage({
            type: "pipeline-point",
            pointType: pointType || type,
            id: keyPrefix + id,
            sid,
            x,
            y,
            parentId,
          });
          branchPoints.push(point);
        }
        return point;
      };

      for (let branch of branches) {
        branchPoints = [];
        const currentPoints = branch.points;
        for (let i = 0; i < currentPoints.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          let [start, end] = [i, i + 1].map((index) => {
            const id = uuid();
            const curr = currentPoints[index];
            const specificPoint = [...createdPoints, ...branchPoints].find(
              ({ sid }) =>
                sid != null && (curr.id === sid || curr.parentId === sid)
            );
            const parentPoint = [...createdPoints, ...branchPoints].find(
              ({ id, parentId }) => {
                if (curr.parentId == null) {
                  return false;
                }
                const parentIsSystem =
                  curr.parentId.indexOf("system-element") >= 0;
                if (parentIsSystem) {
                  return curr.parentId === parentId;
                }

                return curr.parentId === id;
              }
            );
            const branchPoint = branchPoints.find(
              ({ x, y, specifiedShapePoint, sid }) =>
                x === curr.x &&
                y === curr.y &&
                specifiedShapePoint == null &&
                sid == null &&
                curr?.systemType == null &&
                curr?.specifiedShapePoint == null
            );
            return (
              specificPoint ||
              parentPoint ||
              branchPoint ||
              createPoint(
                curr,
                id,
                curr?.systemType === "valve-box"
                  ? "start-point"
                  : curr?.systemType
                  ? curr?.systemType
                  : "l-point"
              )
            );
          });

          plan.addElementToStorage({
            type: "pipe",
            start: start.id,
            end: end.id,
          });
        }
        createdPoints.push(...branchPoints);
      }
      //init
      plan.updatePipesModifiedAt();
    },
    checkPipeElements: action(() => {
      //remove or move to parents
      const points = plan.elements.filter(
        (e) =>
          e.type === "pipeline-point" &&
          (e.pointType === "sprinkler-point" ||
            e.isSystemElementPoint ||
            e.isRzwsPoint ||
            e.isRaisedBedPoint)
      );
      points.forEach((p) => {
        const parent = p.sprinkler;
        if (!parent) {
          plan.removeElementById(p.id);
        } else if (parent.x !== p.x || parent.y !== p.y) {
          p.x = parent.x;
          p.y = parent.y;
        }
      });

      const pointsIds = points.map((point) => point.parentId);

      //add new sprinklers / rzws / raised beds
      const sprinklerElements = plan.elements.filter(
        (e) =>
          pointsIds.indexOf(e.id) < 0 &&
          (e.type === "sprinkler" ||
            e.type === "rzws" ||
            e.type === "raised-bed")
      );
      if (sprinklerElements && sprinklerElements.length > 0) {
        sprinklerElements.forEach((element) => {
          plan.addElementToStorage({
            x: element.x,
            y: element.y,
            pointType: `${element.type}-point`,
            type: "pipeline-point",
            parentId: element.id,
          });
        });
      }

      //add new systemElements
      [
        "water-supply",
        "water-tap-point",
        "water-meter",
        "water-filter",
        "fertilizer",
        "valve-box",
      ].forEach((type) => {
        const newElements = plan.systemElements.filter(
          (e) => e.systemType === type && pointsIds.indexOf(e.id) < 0
        );

        if (newElements && newElements.length > 0) {
          newElements.forEach((el) => {
            plan.addElementToStorage({
              x: el.x,
              y: el.y,
              pointType: type === "valve-box" ? "start-point" : type,
              type: "pipeline-point",
              parentId: el.id,
            });
          });
        }
      });

      plan.updatePipesModifiedAt();
    }),
    recalculateCables: action(async (api, paths, recalculate = null) => {
      try {
        plan.elements.replace(
          plan.elements.filter(
            (e) =>
              e.type !== "irrigationValveCable" && e.type !== "waterMeterCable"
          )
        );

        const valveboxes = plan.getSystemElementsByType("valve-box");
        const waterSupply = plan.getSystemElementsByType("water-supply")?.[0];
        const controller = plan.getSystemElementsByType("controller")?.[0];

        const trenchingResultObj = await api.trenchingWays(
          plan.toJSON,
          [
            ...valveboxes.map((valvebox) => {
              return {
                start: { x: valvebox.x, y: valvebox.y, id: valvebox.id },
                end: { x: controller.x, y: controller.y, id: controller.id },
                type: "irrigationValveCable",
              };
            }),
            {
              start: { x: waterSupply.x, y: waterSupply.y, id: waterSupply.id },
              end: { x: controller.x, y: controller.y, id: controller.id },
              type: "waterMeterCable",
            },
          ],
          paths
        );

        if (trenchingResultObj && trenchingResultObj.result) {
          const { result } = trenchingResultObj;
          if (result && result.length > 0) {
            result
              .filter((el) => {
                return (
                  recalculate == null ||
                  (recalculate != null && recalculate.indexOf(el.way.type) >= 0)
                );
              })
              .forEach((el, idx) => {
                if (el && el.distance != null) {
                  plan.addElementToStorage({
                    type: el.way.type,
                    startId: el.way.start?.id,
                    stopId: el.way.stop?.id,
                    points: el.path.points,
                    distance: el.distance,
                  });
                }
              });
            plan.updatePipesModifiedAt();
          }
        }
      } catch (e) {
        console.error(e);
      }
    }),
    recalculatePipepoints: action((ignoreElementIds = []) => {
      plan.elements.replace(
        plan.elements.filter(
          (e) =>
            (e.type !== "pipe" && e.type !== "pipeline-point") ||
            (e.id && ignoreElementIds.indexOf(e.id) >= 0)
        )
      );
      const sprinklers = plan.elements.filter(
        (e) =>
          e.type === "sprinkler" || e.type === "rzws" || e.type === "raised-bed"
      );
      if (sprinklers && sprinklers.length > 0) {
        sprinklers.forEach((element) => {
          plan.addElementToStorage({
            x: element.x,
            y: element.y,
            pointType: `${element.type}-point`,
            type: "pipeline-point",
            parentId: element.id,
          });
        });
      }

      plan.systemElements
        .filter(
          (elem) =>
            [
              "water-supply",
              "water-tap-point",
              "water-meter",
              "water-filter",
              "fertilizer",
              "valve-box",
            ].indexOf(elem.systemType) >= 0
        )
        .forEach((sysElem) => {
          plan.addElementToStorage({
            x: sysElem.x,
            y: sysElem.y,
            pointType:
              sysElem.systemType === "valve-box"
                ? "start-point"
                : sysElem.systemType,
            type: "pipeline-point",
            parentId: sysElem.id,
          });
        });
      plan.updatePipesModifiedAt();
    }),

    /**
     * Pipeline errors
     */
    pipelineError: null,
    pipelineErrorMessages(selectedElement, hoveredElement = null) {
      if (!hoveredElement) {
        plan.pipelineError = null;
        return [];
      }

      const selectedPipeline = selectedElement.pipelines
        ? selectedElement.pipelines[0]
        : undefined;

      const hoveredPipeline =
        hoveredElement && hoveredElement.pipelines
          ? hoveredElement.pipelines[0]
          : undefined;

      const labels = settingsState
        ? settingsState.texts.properties.pipeline
        : null;

      let errorMessages = [
        ...sprinklerLineErrors({
          hoveredElement,
          selectedElement,
          selectedPipeline,
          hoveredPipeline,
          labels,
          plan,
        }),
        ...driplineLineErrors({
          hoveredElement,
          selectedElement,
          selectedPipeline,
          hoveredPipeline,
          labels,
        }),
        ...pressureTubingLineErrors({
          hoveredElement,
          selectedElement,
          selectedPipeline,
          hoveredPipeline,
          labels,
        }),
      ];

      plan.pipelineError =
        plan.pipelineHasDrawing && errorMessages.length > 0
          ? errorMessages.map(({ text }) => text)[0]
          : null;

      return errorMessages;
    },

    /**
     * Changes the transparency of pipelines that are not available for connection
     * @param selectedElement
     */
    unconnectablePipelines: action((selectedElement) => {
      const selectedPipeline =
        selectedElement && selectedElement.pipelines
          ? selectedElement.pipelines[0]
          : undefined;

      const pipelineSetHidden = (pipeline, hasHidden = true) => {
        pipeline.sprinklers.forEach((sprinkler) => {
          if (sprinkler) sprinkler.setHidden(hasHidden);
        });
        pipeline.setHidden(hasHidden);
      };

      if (!plan.pipelines) {
        return;
      }

      if (selectedPipeline) {
        plan.pipelines.forEach((pipeline) => {
          if (pipeline.id !== selectedPipeline.id) {
            const labels = settingsState
              ? settingsState.texts.properties.pipeline
              : null;

            let errorMessages = [
              ...sprinklerLineErrors({
                selectedElement,
                selectedPipeline,
                hoveredPipeline: pipeline,
                labels,
                plan,
              }),
              ...driplineLineErrors({
                selectedElement,
                selectedPipeline,
                hoveredPipeline: pipeline,
                labels,
              }),
              ...pressureTubingLineErrors({
                selectedElement,
                selectedPipeline,
                hoveredPipeline: pipeline,
                labels,
              }),
            ];

            if (errorMessages.length > 0) {
              pipelineSetHidden(pipeline, true);
            }
          }
        });
      } else {
        plan.pipelines.map((p) => pipelineSetHidden(p, false));
      }
    }),
  });
  plan.recalculatePipelineCircuits();
  plan.updatePipesModifiedAt();
};
