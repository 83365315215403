import {APP_CONFIG} from "./env.config";

function initSib() {
  if (APP_CONFIG.NODE_ENV !== "production") return;

  (function () {
    window.sib = {
      equeue: [],
      client_key:APP_CONFIG?.REACT_APP_SIB,
    };
    window.sendinblue = {};
    for (
      var j = ["track", "identify", "trackLink", "page"], i = 0;
      i < j.length;
      i++
    ) {
      (function (k) {
        window.sendinblue[k] = function () {
          var arg = Array.prototype.slice.call(arguments);
          (
            window.sib[k] ||
            function () {
              var t = {};
              t[k] = arg;
              window.sib.equeue.push(t);
            }
          )(arg[0], arg[1], arg[2]);
        };
      })(j[i]);
    }
    var n = document.createElement("script"),
      etn = document.getElementsByTagName("script")[0];

    n.type = "text/javascript";
    n.id = "sendinblue-js";
    n.async = !0;
    n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key;
    etn.parentNode.insertBefore(n, etn);

    // window.sendinblue.page();
  })();
}

export default initSib;
