import React from "react";
import { observer } from "mobx-react";

import {
  sizeInMetersByPixel,
  sizeInPixelByMeters,
} from "@dvsproj/ipat-core/planUtils";
import { toFixedPrecision } from "@dvsproj/ipat-core/formatter";

import Sprinkler from "../sprinklers/Sprinkler";
import Properties from "./Properties";
import Dropdown from "./../elements/Dropdown";
import NumberInput from "../inputs/NumberInput";
import InvalidPropertiesPopup from "../popups/InvalidPropertiesPopup";
import ToolButton from "../elements/ToolButton";

import { ReactComponent as RadiusSVG } from "./../../assets/radius.svg";
import { ReactComponent as StartAngleSVG } from "./../../assets/start-angle.svg";
import { ReactComponent as IrrigationAngleSVG } from "./../../assets/irrigation-angle.svg";
import { ReactComponent as PlusSVG } from "./../../assets/plus-small.svg";
import { ReactComponent as MinusSVG } from "./../../assets/minus-small.svg";
import { ReactComponent as LongSideSVG } from "./../../assets/long-side.svg";
import { ReactComponent as ShortSideSVG } from "./../../assets/short-side.svg";
import { ReactComponent as PositionAngleSVG } from "./../../assets/position-angle.svg";
import { ReactComponent as InvalidSVG } from "./../../assets/stop.svg";
import { useIntl } from "react-intl";

import { useFunctionalKeysObserver } from "../simpleComponents/FunctionalKeysObserverComponent";
import useStores from "../../hooks/useStores";

const INCREASED_DELTA_VALUES = {
  size: 1,
  angle: 45,
};
function SettingsItem({
  icon,
  title,
  value,
  valueDelta = 1,
  valueDeltaInc = INCREASED_DELTA_VALUES.size,
  valueIsNumber = false,
  valueIsNegative = false,
  valueUnit,
  save = () => {},
  onChange = () => {},
  normalizeValue = (val) => {
    return val;
  },
  disabled,
  invalid,
}) {
  const functionalKeyPressed = useFunctionalKeysObserver();

  const delta = React.useRef({ value: valueDelta });
  React.useEffect(() => {
    delta.current = {
      value: functionalKeyPressed ? valueDeltaInc : valueDelta,
    };
  }, [functionalKeyPressed, valueDelta, valueDeltaInc]);

  return (
    <div
      className={`settings-item ${disabled ? "disabled" : ""} ${
        invalid ? "invalid" : ""
      }`}
    >
      <div className="setting-info">
        <div className="setting-icon-wrap">
          <div className="setting-icon">{icon}</div>
          <div className="setting-value">
            <NumberInput
              type="text"
              value={value}
              onChange={(val) => {
                onChange(val);
                save();
              }}
              isInteger={!valueIsNumber}
              isNegative={valueIsNegative}
              disabled={disabled}
              onBlur={() => {
                onChange(normalizeValue(value));
              }}
            />
            {valueUnit}
          </div>
        </div>
        <div className="setting-title">{title}</div>
      </div>

      <div className="setting-control">
        <ToolButton
          onClick={() => {
            if (!disabled) {
              const val = isNaN(value * 1)
                ? delta.current.value
                : Number((value * 1 + delta.current.value).toFixed(1));
              onChange(normalizeValue(val));
              save();
            }
          }}
          className="setting-control-item"
        >
          <PlusSVG />
        </ToolButton>
        <ToolButton
          onClick={() => {
            if (!disabled) {
              const val = isNaN(value * 1)
                ? -delta.current.value
                : Number((value * 1 - delta.current.value).toFixed(1));
              onChange(normalizeValue(val));
              save();
            }
          }}
          className="setting-control-item"
        >
          <MinusSVG />
        </ToolButton>
      </div>
    </div>
  );
}

function CircleProperties({
  disabled,
  circleRadius,
  changeCircleRadius,
  startAngle,
  changeStartAngle,
  circleSectorAngle,
  changeCircleSectorAngle,
  invalid,
  onChange = () => {},
  scale,
  precision,
  labels,
}) {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <SettingsItem
        icon={<RadiusSVG />}
        title={formatMessage({ id: labels.radius })}
        value={toFixedPrecision(
          sizeInMetersByPixel(circleRadius, scale),
          precision
        )}
        valueDelta={0.1}
        valueDeltaInc={INCREASED_DELTA_VALUES.size}
        valueIsNumber={true}
        valueUnit="m"
        save={onChange}
        onChange={(val) => {
          changeCircleRadius(sizeInPixelByMeters(val, scale));
        }}
        disabled={disabled}
        invalid={invalid.circleRadius}
      />
      <SettingsItem
        icon={<StartAngleSVG />}
        title={formatMessage({ id: labels.startAngle })}
        value={startAngle}
        valueUnit="°"
        save={onChange}
        onChange={(val) => {
          changeStartAngle(val);
        }}
        normalizeValue={(val) => {
          return (360 + val) % 360;
        }}
        disabled={disabled}
        invalid={invalid.startAngle}
        valueDeltaInc={INCREASED_DELTA_VALUES.angle}
      />
      <SettingsItem
        icon={<IrrigationAngleSVG />}
        title={formatMessage({ id: labels.irrigationAngle })}
        value={circleSectorAngle}
        valueUnit="°"
        save={onChange}
        onChange={(val) => {
          changeCircleSectorAngle(val);
        }}
        normalizeValue={(val) => {
          let tVal = (360 + val) % 360;
          return tVal === 0 ? 360 : tVal;
        }}
        disabled={disabled}
        invalid={invalid.circleSectorAngle}
        valueDeltaInc={INCREASED_DELTA_VALUES.angle}
      />
    </React.Fragment>
  );
}

function RectProperties({
  disabled,
  startAngle,
  changeStartAngle,
  rectWidth,
  changeRectWidth,
  rectHeight,
  changeRectHeight,
  rectType,
  rectTypes,
  changeRectType,
  invalid,
  onChange = () => {},
  scale,
  precision,
  labels,
}) {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <div className="settings-item">
        <Dropdown
          current={rectType}
          onChange={(val) => {
            changeRectType(val);
            onChange();
          }}
          data={rectTypes.map((e) => ({
            ...e,
            label: formatMessage({ id: e.label }),
          }))}
          disabled={disabled}
        />
      </div>
      <SettingsItem
        icon={<LongSideSVG />}
        title={formatMessage({ id: labels.width })}
        value={toFixedPrecision(
          sizeInMetersByPixel(rectWidth, scale),
          precision
        )}
        valueDelta={0.1}
        valueDeltaInc={INCREASED_DELTA_VALUES.size}
        valueUnit="m"
        valueIsNumber={true}
        save={onChange}
        onChange={(val) => {
          changeRectWidth(sizeInPixelByMeters(val, scale));
        }}
        disabled={disabled}
        invalid={invalid.rectWidth}
      />
      <SettingsItem
        icon={<ShortSideSVG />}
        title={formatMessage({ id: labels.height })}
        value={toFixedPrecision(
          sizeInMetersByPixel(rectHeight, scale),
          precision
        )}
        valueDelta={0.1}
        valueDeltaInc={INCREASED_DELTA_VALUES.size}
        valueIsNumber={true}
        valueUnit="m"
        save={onChange}
        onChange={(val) => {
          changeRectHeight(sizeInPixelByMeters(val, scale));
        }}
        disabled={disabled}
        invalid={invalid.rectHeight}
      />
      <SettingsItem
        icon={<PositionAngleSVG />}
        title={formatMessage({ id: labels.startAngle })}
        value={startAngle}
        valueUnit="°"
        save={onChange}
        onChange={(val) => {
          changeStartAngle(val);
        }}
        normalizeValue={(val) => {
          return (360 + val) % 360;
        }}
        disabled={disabled}
        invalid={invalid.startAngle}
        valueDeltaInc={INCREASED_DELTA_VALUES.angle}
      />
    </React.Fragment>
  );
}

function ElementIcon({ color, invalid, nozzleType, onClick = () => {} }) {
  return (
    <React.Fragment>
      {!invalid ? (
        <React.Fragment>
          <svg width="36" height="36" viewBox="0 0 16 16">
            <g transform="translate(8, 8)">
              <Sprinkler color={color} nozzleType={nozzleType} />
            </g>
          </svg>
        </React.Fragment>
      ) : (
        <ToolButton className="invalid-icon" onClick={onClick}>
          <InvalidSVG />
        </ToolButton>
      )}
    </React.Fragment>
  );
}

function SprinklerProperties({
  title,
  color,
  sprinklerType,
  nozzleType,
  circleRadius,
  changeCircleRadius,
  startAngle,
  changeStartAngle,
  circleSectorAngle,
  changeCircleSectorAngle,
  rectHeight,
  changeRectHeight,
  rectWidth,
  changeRectWidth,
  rectType,
  rectTypes,
  changeRectType,
  onDisable,
  disabled,
  onRemove,
  invalid,
  hasInvalid,
  onChangeDebounced,
  onChange,
  scale,
  precision,
  labels,
}) {
  const { formatMessage } = useIntl();

  var PropertiesElement;
  let invalidLabels;
  switch (sprinklerType) {
    case "circle":
      PropertiesElement = (
        <CircleProperties
          disabled={disabled}
          circleRadius={circleRadius}
          changeCircleRadius={changeCircleRadius}
          startAngle={startAngle}
          changeStartAngle={changeStartAngle}
          circleSectorAngle={circleSectorAngle}
          changeCircleSectorAngle={changeCircleSectorAngle}
          invalid={invalid}
          onChange={onChangeDebounced}
          scale={scale}
          precision={precision}
          labels={labels.sprinklerCircle ? labels.sprinklerCircle : {}}
        />
      );
      invalidLabels =
        Object.keys(labels).length > 0 ? labels.sprinklerCircle.invalid : {};
      break;
    case "rect":
      PropertiesElement = (
        <RectProperties
          disabled={disabled}
          startAngle={startAngle}
          changeStartAngle={changeStartAngle}
          rectWidth={rectWidth}
          changeRectWidth={changeRectWidth}
          rectHeight={rectHeight}
          changeRectHeight={changeRectHeight}
          rectType={rectType}
          rectTypes={rectTypes}
          changeRectType={changeRectType}
          invalid={invalid}
          onChange={onChangeDebounced}
          scale={scale}
          precision={precision}
          labels={labels.sprinklerRect ? labels.sprinklerRect : {}}
        />
      );
      invalidLabels =
        Object.keys(labels).length > 0 ? labels.sprinklerRect.invalid : {};
      break;
    default:
      break;
  }
  const [invalidPopup, setInvalidPopup] = React.useState(true);

  return (
    <Properties
      onDisable={onDisable}
      disabled={disabled}
      onRemove={onRemove}
      onChange={onChange}
      header={{
        icon: (
          <ElementIcon
            color={color}
            nozzleType={nozzleType}
            invalid={hasInvalid}
            onClick={() => setInvalidPopup(true)}
          />
        ),
        title: hasInvalid
          ? formatMessage({ id: invalidLabels.title })
          : formatMessage({ id: title }),
      }}
    >
      {hasInvalid ? (
        <InvalidPropertiesPopup
          labels={invalidLabels ? invalidLabels.popup : {}}
          hasActive={invalidPopup}
          changeActive={setInvalidPopup}
        />
      ) : null}
      {PropertiesElement}
    </Properties>
  );
}

let SprinklerPropertiesWithState = () => {
  const { uiState } = useStores();
  const { selectedElement } = uiState;
  return (
    <SprinklerProperties
      title={selectedElement.name}
      color={selectedElement.color}
      sprinklerType={selectedElement.sprinklerType}
      nozzleType={selectedElement.nozzleType}
      circleRadius={selectedElement.circleRadius}
      changeCircleRadius={selectedElement.changeCircleRadius}
      startAngle={selectedElement.realStartAngle}
      changeStartAngle={selectedElement.changeStartAngle}
      circleSectorAngle={selectedElement.realCircleSectorAngle}
      changeCircleSectorAngle={selectedElement.changeCircleSectorAngle}
      rectHeight={selectedElement.rectHeight}
      changeRectHeight={selectedElement.changeRectHeight}
      rectWidth={selectedElement.rectWidth}
      changeRectWidth={selectedElement.changeRectWidth}
      rectType={selectedElement.rectType}
      rectTypes={selectedElement.rectTypes}
      changeRectType={selectedElement.changeRectType}
      onDisable={selectedElement.onDisable}
      disabled={selectedElement.disabled || !uiState.planIsEditable}
      invalid={selectedElement.invalid}
      hasInvalid={selectedElement.hasInvalid}
      onRemove={uiState.onRemoveElement}
      onChange={
        uiState.reactions ? uiState.reactions.onSprinklerChange : () => {}
      }
      onChangeDebounced={
        uiState.reactions
          ? uiState.reactions.onSprinklerChangeDebounced
          : () => {}
      }
      scale={uiState.plan.scale}
      precision={uiState.precision}
      labels={
        uiState.settingsState
          ? uiState.settingsState.texts.properties.sprinklers
          : {}
      }
    />
  );
};

SprinklerPropertiesWithState = observer(SprinklerPropertiesWithState);
export default SprinklerPropertiesWithState;
