import React from "react";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";

import ScaleTool from "./ScaleTool";
import { ReactComponent as DvsSVG } from "./../../assets/dvs-sign.svg";
import SprinklerProperties from "./SprinklerProperties";
import SystemElementProperties from "./SystemElementProperties";
import AreaProperties from "./AreaProperties";
import SensorProperties from "./SensorProperties";
import RzwsProperties from "./RzwsProperties";
import RaisedBedProperties from "./RaisedBedProperties";

import PipelineProperties from "./pipeline/PipelineProperties";
import useStores from "../../hooks/useStores";

function RightSide({ selectedTool, setSelectedTool, selectedElement, scale }) {

  const [isVisible, setIsVisible] = useState(false); // State to track element visibility
  const [height, setHeight] = useState('168px');

  useEffect(() => {
    if (Element) {
      setIsVisible(false); // Hide the elements initially when Element is truthy
      const timer = setTimeout(() => {
        setIsVisible(true);// Show the elements after the scale transition
      }, 800); // Match this duration with your CSS transition duration
      return () => clearTimeout(timer); // Clean up the timer on unmount
    } else {
      setIsVisible(false);// Hide elements when Element is falsy
    }
  }, [Element]);


  var Element;
  if (selectedTool === "scale") {
    Element = (
      <ScaleTool
        onClick={() => {
          if (scale === undefined) {
            setSelectedTool("opacity");
          } else {
            setSelectedTool();
          }
        }}
      />
    );
  } else if (selectedElement != null) {
    switch (selectedElement.type) {
      case "sprinkler":
        Element = <SprinklerProperties />;
        break;
      case "area":
        Element = <AreaProperties />;
        break;
      case "system-element":
        Element = <SystemElementProperties />;
        break;
      case "sensor":
        Element = <SensorProperties />;
        break;
      case "pipe":
        Element = <PipelineProperties />;
        break;
      case "pipeline-point":
        Element = <PipelineProperties />;
        break;
      case "rzws":
        Element = <RzwsProperties />;
        break;
      case "raised-bed":
        Element = <RaisedBedProperties />;
        break;
      default:
        break;
    }
  }

  return (
    // <div className={`right-side`}>

    <div className={`right-side ${Element ? 'scale-up' : ''}`}>
      {Element ? Element : null}
      

      {!Element && (
        <div className="right-side-footer">
          <div className="right-side-icon">
            <i>
              {/* <DvsSVG /> */}
            </i>
          </div>
        </div>
      )}
    </div>
  );
}

let RightSideWithState = () => {
  const { uiState } = useStores();
  return (
    <RightSide
      currentStep={uiState.currentStep}
      nextStep={uiState.nextStep}
      selectedTool={uiState.selectedTool}
      setSelectedTool={uiState.setSelectedTool}
      selectedElement={uiState.selectedElement}
      opacity={uiState.plan ? uiState.plan.opacity : undefined}
      scale={uiState.plan ? uiState.plan.scale : undefined}
    />
  );
};

RightSideWithState = observer(RightSideWithState);

export default RightSideWithState;
