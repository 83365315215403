import { observer } from "mobx-react";
import * as React from "react";
import { useIntl } from "react-intl";
import { urlDecorator } from "../../../utils/api";
import PlanBackgroundForm from "./PlanBackgroundForm";
import PlanForm from "./PlanForm";
import PlanNameForm from "./PlanNameForm";
import useStores from "../../../hooks/useStores";

const ComponentByStep = [PlanNameForm, PlanBackgroundForm];

const Title = ({ step }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {formatMessage({ id: "texts.steps.upload.popup.uploadPlan.title" })}
      &nbsp;
      <strong>
        {formatMessage(
          { id: "texts.steps.upload.popup.uploadPlan.step" },
          { curStep: step + 1, maxStep: ComponentByStep.length }
        )}
      </strong>
    </>
  );
};

const PlanSettingsStep = observer(() => {
  const { formatMessage } = useIntl();
  const { uiState } = useStores();

  const [step, changeStep] = React.useState(0);

  const Component = React.useMemo(() => ComponentByStep[step], [step]);

  const labels = React.useMemo(
    () =>
      uiState.settingsState
        ? uiState.settingsState.texts.steps.upload.popup
        : null,
    [uiState.settingsState]
  );

  if (
    !uiState.user?.auth ||
    uiState.calculatingTitle != null ||
    uiState.plan != null ||
    uiState.step.name !== "upload"
  ) {
    return null;
  }

  return (
    <PlanForm
      title={<Title step={step} />}
      prev={step - 1 < 0 ? undefined : () => changeStep(step - 1)}
      next={step + 1 >= ComponentByStep.length ? undefined : () => changeStep(step + 1)}
    >
      <Component
        labels={labels}
        planName={uiState.planName}
        setPlanName={uiState.setPlanName}
        termsURL={
          uiState.settingsState
            ? urlDecorator(
                formatMessage({ id: uiState.settingsState.links.termsURL })
              )
            : "/"
        }
        createPlan={(plan, step) => {
          uiState.plan = {
            name: uiState.planName,
            ...plan,
          };

          switch (step) {
            case "max-possible":
              if (uiState.maxPossibleStep > 1) {
                uiState.toStep(uiState.maxPossibleStep - 1);
              } else {
                uiState.nextStep();
              }
              break;
            case "max-calculated":
              if (uiState.maxPossibleStep > 1) {
                uiState.toMaxCalculatedStep();
              } else {
                uiState.nextStep();
              }
              break;
            case "scale":
              uiState.toStep(1);
              uiState.setSelectedTool("scale");
              break;
            default:
              uiState.toStep(1);
              break;
          }
        }}
        hasDev={uiState.hasDev}
      />
    </PlanForm>
  );
});

export default PlanSettingsStep;