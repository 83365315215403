import { ceilWithThreshold } from "@dvsproj/ipat-core/formatter"

function driplineKits(settings, plan) {
  const { bomList, elements } = settings
  const { driplineKit, driplineConnectionPoints } = elements

  const driplines = plan.pipelines.filter(p => p.lineType === "dripline")

  const totalDriplineAreaSize = driplines.reduce((acc, pipeline) => {
    if (pipeline.driplinePoint == null) return acc

    const { driplineArea } = pipeline.driplinePoint
    acc += driplineArea.size

    return acc
  }, 0)

  let bomItems = []
  if (totalDriplineAreaSize === 0) return bomItems

  const totalDriplineKitsCount = Math.max(
      ceilWithThreshold(
          totalDriplineAreaSize,
          driplineKit.maxArea,
          driplineKit.areaThreshold
      ),
      1
  )

  const driplineKitByTube = driplineKit.types.find(
      t => t.type === plan.irrigationTubeType
  )
  if (driplineKitByTube == null) {
    throw new Error(`Dripline kit for ${plan.irrigationTubeType} not found`)
  }

  bomItems.push({
    bomId: driplineKitByTube.bomId,
    qualityList: bomList[driplineKitByTube.bomId],
    quantity: totalDriplineKitsCount
  })

  const totalDriplineValvesCount = driplines.reduce((acc, pipeline) => {
    if (pipeline.driplinePoint == null) return acc

    const { driplineValvesCount } = pipeline.driplinePoint
    acc += driplineValvesCount

    return acc
  }, 0)

  const driplineConnectionPointByType = driplineConnectionPoints.find(
      t => t.type === plan.irrigationTubeType
  )
  if (driplineConnectionPointByType == null) {
    throw new Error(
        `Dripline connection point for ${plan.irrigationTubeType} not found`
    )
  }

  bomItems.push({
    bomId: driplineConnectionPointByType.bomId,
    qualityList: bomList[driplineConnectionPointByType.bomId],
    quantity: Math.max(totalDriplineValvesCount - totalDriplineKitsCount, 0)
  })

  return bomItems
}

export { driplineKits }
