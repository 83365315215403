//DON'T REMOVE THIS LINE
import React from "react";
import { observer } from "mobx-react";

import { pixelSizeByZoom } from "@dvsproj/ipat-core/planUtils";

import Draggable from "./Draggable";
import usePlanSizeValidation from "../hooks/usePlanSizeValidation";
import useStores from "../hooks/useStores";

export const ControllerSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#ff7600"
      d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0Z"
    />
    <g transform="translate(10 9.9)">
      <path
        fill="#fff"
        d="M14,13.9a2,2,0,0,1-4,0c0-1.1,2-4,2-4S14,12.7,14,13.9Zm-1.1-.7a.5.5,0,1,0,.5.5A.472.472,0,0,0,12.9,13.2Z"
        transform="translate(-10 -9.9)"
      />
    </g>
    <path
      fill="#fff"
      d="M12,20.3A8.28,8.28,0,0,1,3.7,12,8.139,8.139,0,0,1,8.9,4.3a.731.731,0,0,1,1,.4.731.731,0,0,1-.4,1A6.585,6.585,0,0,0,5.2,12a6.8,6.8,0,0,0,13.6,0,6.951,6.951,0,0,0-4.4-6.4.7.7,0,0,1-.4-1,.7.7,0,0,1,1-.4A8.454,8.454,0,0,1,20.4,12,8.43,8.43,0,0,1,12,20.3Z"
      transform="translate(0 0)"
    />
    <path
      fill="#fff"
      d="M12,8a.789.789,0,0,1-.8-.8v-5a.789.789,0,0,1,.8-.8.736.736,0,0,1,.8.8v5A.789.789,0,0,1,12,8Z"
      transform="translate(0 0)"
    />
  </svg>
);

export const WaterSupplySVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#006cff"
      d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0Z"
    />
    <g>
      <path
        fill="#fff"
        d="M15.8,7.7H10.7V9.1h-.2a5.653,5.653,0,0,0-3,.8c-1.2.8-2.5,2-2.6,5.4H8.3c0-3,1.8-2.9,2.3-2.9h0v.7h5.1v-.7h1.5V9.1H15.7V7.7Zm.9,2v2H15.1v.7H11.4v-.7h-.9c-1.7,0-2.6,1-2.8,2.9h-2a4.982,4.982,0,0,1,2.2-4.2,4.545,4.545,0,0,1,2.6-.7h.9V8.3h3.7V9.7h1.6Z"
      />
      <rect
        fill="#fff"
        width="1.9"
        height="6.8"
        transform="translate(17.9 7.2)"
      />
      <path
        fill="#fff"
        d="M10.8,5h1.8V6.9h1.2V5h1.8a.472.472,0,0,0,.5-.5V4.2a.472.472,0,0,0-.5-.5H13.8V3.5a.6.6,0,0,0-1.2,0v.3H10.8a.472.472,0,0,0-.5.5v.2A.536.536,0,0,0,10.8,5Z"
      />
      <path
        fill="#fff"
        d="M5.3,18.5a1.324,1.324,0,0,0,1.3,1.3A1.415,1.415,0,0,0,8,18.5a9.671,9.671,0,0,0-1.3-2.7A9.408,9.408,0,0,0,5.3,18.5Z"
      />
    </g>
  </svg>
);

export const ValveBoxSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#3e4346"
      d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0Z"
    />
    <path
      fill="#fff"
      d="M5,5V19H19V5Zm7,7L6.1,17.9h0V6.1h0L12,12l5.9-5.9V17.9Z"
    />
  </svg>
);

const WsNotEnoughSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M12 0a12 12 0 1 0 12 12A12.035 12.035 0 0 0 12 0z"
      fill="#006cff"
    />
    <g>
      <path
        d="M15.8 7.7h-5.1v1.4h-.2a5.653 5.653 0 0 0-3 .8c-1.2.8-2.5 2-2.6 5.4h3.4c0-3 1.8-2.9 2.3-2.9v.7h5.1v-.7h1.5V9.1h-1.5V7.7zm.9 2v2h-1.6v.7h-3.7v-.7h-.9c-1.7 0-2.6 1-2.8 2.9h-2a4.982 4.982 0 0 1 2.2-4.2 4.545 4.545 0 0 1 2.6-.7h.9V8.3h3.7v1.4h1.6z"
        fill="#fff"
      />
      <path d="M0 0H1.9V6.8H0z" fill="#fff" transform="translate(17.9 7.2)" />
      <path
        d="M10.8 5h1.8v1.9h1.2V5h1.8a.472.472 0 0 0 .5-.5v-.3a.472.472 0 0 0-.5-.5h-1.8v-.2a.6.6 0 0 0-1.2 0v.3h-1.8a.472.472 0 0 0-.5.5v.2a.536.536 0 0 0 .5.5z"
        fill="#fff"
      />
      <path
        d="M5.3 18.5a1.324 1.324 0 0 0 1.3 1.3A1.415 1.415 0 0 0 8 18.5a9.671 9.671 0 0 0-1.3-2.7 9.408 9.408 0 0 0-1.4 2.7z"
        fill="#fff"
      />
    </g>
    <path
      d="M12 0a12 12 0 1 0 12 12A11.987 11.987 0 0 0 12 0zm0 1.5a10.7 10.7 0 0 1 6.6 2.325l-14.775 14.7A10.452 10.452 0 0 1 12 1.5zm0 21a10.7 10.7 0 0 1-6.6-2.325L20.1 5.4A10.508 10.508 0 0 1 12 22.5z"
      fill="#ff7600"
    />
  </svg>
);

export const WaterTapSVG = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <g>
        <path d="M9,0A9,9,0,1,0,18,9,9.035,9.035,0,0,0,9,0Z" fill="#006cff" />
        <path
          fill="#f5f5f5"
          d="M 14.38 12.93 a 6.342 6.342 90 0 1 -5.13 2.635 a 6.219 6.219 90 0 1 -3.695 -1.1 l -1.56 2.085 c -0.015 -0.01 -0.025 -0.02 -0.04 -0.03 c -0.125 -0.085 -0.245 -0.175 -0.365 -0.275 c -0.015 -0.01 -0.03 -0.025 -0.045 -0.035 l 1.935 -2.57 l 0.225 0.215 a 5.4715 5.4715 90 0 0 3.545 1.15 a 5.7895 5.7895 90 0 0 4.675 -2.4 a 0.645 0.645 90 0 0 -0.125 -0.855 l -0.065 -0.05 a 0.6145 0.6145 90 0 0 -0.475 -0.14 a 0.6845 0.6845 90 0 0 -0.475 0.285 a 4.3885 4.3885 90 0 1 -2.05 1.53 a 0.286 0.286 90 0 1 -0.36 -0.17 a 0.28 0.28 90 0 1 0.17 -0.36 a 3.821 3.821 90 0 0 1.785 -1.335 a 1.265 1.265 90 0 1 0.865 -0.51 a 1.1965 1.1965 90 0 1 0.905 0.265 l 0.06 0.055 A 1.194 1.194 90 0 1 14.38 12.93 Z M 9.253 2.4645 s -2.85 4.069 -2.8715 5.688 a 0.2065 0.2065 90 0 1 -0.0045 0.023 a 2.874 2.874 90 1 0 5.75 0 v -0.023 C 12.097 6.5335 9.253 2.4645 9.253 2.4645 Z m 2.0135 5.697 a 0.736 0.736 90 1 1 -1.472 0 v -0.009 a 0.736 0.736 90 1 1 1.472 0 Z M 9.94 13.18 l -0.005 0.06 a 0.9785 0.9785 90 0 1 -0.96 0.89 h -0.02 a 4.8635 4.8635 90 0 1 -3.055 -1.16 a 0.2835 0.2835 90 0 1 0.37 -0.43 a 4.2875 4.2875 90 0 0 2.695 1.03 a 0.4 0.4 90 0 0 0.405 -0.38 l 0.01 -0.06 a 0.414 0.414 90 0 0 -0.095 -0.3 a 0.508 0.508 90 0 0 -0.34 -0.16 a 4.9115 4.9115 90 0 1 -3.19 -1.565 a 0.437 0.437 90 0 0 -0.575 -0.055 l -3.685 2.765 a 0.104 0.104 90 0 1 -0.02 -0.03 q -0.135 -0.225 -0.255 -0.465 l 3.625 -2.72 a 0.996 0.996 90 0 1 1.315 0.115 a 4.3645 4.3645 90 0 0 2.81 1.395 a 1.0565 1.0565 90 0 1 0.74 0.355 A 0.947 0.947 90 0 1 9.94 13.18 Z"
        />
      </g>
    </svg>
  );
};

export const FertilizerSVG = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g>
        <path
          d="M12 0a12 12 0 1 0 12 12A12.035 12.035 0 0 0 12 0z"
          fill="#26791c"
        />
        <path
          d="M25.636 15.1a6.5 6.5 0 0 1-3.091 7.761c-.177.1-.357.179-.537.256a3.9 3.9 0 0 1-.25.759 7.129 7.129 0 0 0 4.525-8.833 5.738 5.738 0 0 0-.646.056"
          transform="translate(-7.31 -4.6)"
          fill="#f5f5f5"
        />
        <path
          d="M12.579 23.823a6.5 6.5 0 0 1-5.171-7.347 3.877 3.877 0 0 1-.525-.6 7.1 7.1 0 0 0 5.434 8.52 5.9 5.9 0 0 0 .262-.572"
          transform="translate(-1.705 -4.912)"
          fill="#f5f5f5"
        />
        <path
          d="M27.022 12.325a3.371 3.371 0 0 0-3.805-1.254 3.366 3.366 0 0 0-1.948 3.492c.03.213.129.332.3.351a.234.234 0 0 0 .036 0 .323.323 0 0 0 .283-.173 5.573 5.573 0 0 1 2.18-1.451.251.251 0 0 0 .041-.016 5.555 5.555 0 0 1 2.588-.4.323.323 0 0 0 .351-.09.351.351 0 0 0-.021-.462m-3.166.375a5.743 5.743 0 0 0-1.514.8 3.288 3.288 0 0 1 2.207-1.407l-.037-.311a3.656 3.656 0 0 0-2.544 1.7 2.5 2.5 0 0 1 1.477-1.826 2.438 2.438 0 0 1 .834-.15 2.821 2.821 0 0 1 1.8.719 6.158 6.158 0 0 0-2.218.475"
          transform="translate(-7.12 -3.05)"
          fill="#f5f5f5"
        />
        <path
          d="M12.18 13.307a.32.32 0 0 0-.307-.191 5.545 5.545 0 0 1-2.347-1.162.372.372 0 0 0-.035-.027 5.552 5.552 0 0 1-1.638-2.043.322.322 0 0 0-.253-.259c-.168-.031-.3.054-.389.252a3.376 3.376 0 0 0 .817 3.92 3 3 0 0 0 1.867.643 3.735 3.735 0 0 0 2.132-.7.347.347 0 0 0 .156-.432m-3.762 0a2.619 2.619 0 0 1-.821-2.562 6.156 6.156 0 0 0 1.521 1.683 5.746 5.746 0 0 0 1.449.911 3.287 3.287 0 0 1-2.32-1.208l-.252.188a3.658 3.658 0 0 0 2.756 1.35 2.506 2.506 0 0 1-2.331-.363"
          transform="translate(-1.781 -2.582)"
          fill="#f5f5f5"
        />
        <path
          d="M17.153 21.994a.346.346 0 0 0-.453.08.323.323 0 0 0-.013.362 5.55 5.55 0 0 1 .168 2.614.259.259 0 0 0-.007.043 5.56 5.56 0 0 1-.947 2.441.323.323 0 0 0-.1.35.329.329 0 0 0 .348.215h.063a3.373 3.373 0 0 0 2.988-2.672 3.366 3.366 0 0 0-2.049-3.433m1.429 3.337a2.618 2.618 0 0 1-1.808 1.993 6.14 6.14 0 0 0 .7-2.158 5.707 5.707 0 0 0 .065-1.711 3.289 3.289 0 0 1 .115 2.614l.288.124a3.658 3.658 0 0 0-.2-3.053 2.5 2.5 0 0 1 .843 2.192"
          transform="translate(-5.086 -7.172)"
          fill="#f5f5f5"
        />
        <path
          d="M17.845 18.936a2 2 0 1 0-.945.239 2 2 0 0 0 .945-.239m-2.154-1.106a1.369 1.369 0 1 1 1.855.554 1.371 1.371 0 0 1-1.855-.554"
          transform="translate(-4.756 -4.654)"
          fill="#f5f5f5"
        />
        <path
          d="M12.67 7.611a7.1 7.1 0 0 0-2.843 1.835 5.726 5.726 0 0 0 .378.518 6.225 6.225 0 0 1 2.814-1.8 2.524 2.524 0 0 1-.35-.557"
          transform="translate(-2.868 -1.834)"
          fill="#f5f5f5"
        />
        <path
          d="M21.459 7.585a2.523 2.523 0 0 1-.336.547 6.559 6.559 0 0 1 2.059 1.057 3.748 3.748 0 0 1 .8-.145 7.171 7.171 0 0 0-2.523-1.459"
          transform="translate(-7.074 -1.824)"
          fill="#f5f5f5"
        />
        <path
          d="M16.883 5.649a1.894 1.894 0 0 0 1.883-1.9C18.766 2.692 16.883 0 16.883 0S15 2.692 15 3.744a1.894 1.894 0 0 0 1.883 1.9m.838-2.525a.489.489 0 1 1-.484.49.487.487 0 0 1 .484-.49"
          transform="translate(-4.794 1)"
          fill="#f5f5f5"
        />
      </g>
    </svg>
  );
};

export const AirCompressorSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="#7826ff" d="M24 12A12 12 0 1 1 12 0a12 12 0 0 1 12 12" />
    <g transform="translate(-2.822 -1.371)">
      <path
        d="M19.489 8.78a4.667 4.667 0 1 0-4.666 4.667 4.671 4.671 0 0 0 4.666-4.667m-.7 0a3.964 3.964 0 1 1-3.964-3.964 3.969 3.969 0 0 1 3.962 3.964M17.458 6.9l-1.864 1.869c0 .028.008.055.008.083a.817.817 0 1 1-.817-.817.8.8 0 0 1 .209.031l1.816-1.817s.01-.005.014-.009a3.248 3.248 0 1 0 .634.665m-1.537 8.8v-1.249h-2.2v1.251H11.6v1h6.452v-1zm5.255 2H8.467v2.667h12.71zm-3.129 3.6H11.6v1h6.453z"
        fill="#f5f5f5"
      />
    </g>
  </svg>
);

export const WaterFilterSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 0a12 12 0 1 0 12 12A12.035 12.035 0 0 0 12 0z"
      fill="#00c4ff"
    />
    <g fill="#f5f5f5">
      <path
        d="M16.791 26.808A1.8 1.8 0 0 0 18.581 25c0-1-1.791-3.56-1.791-3.56S15 24 15 25a1.8 1.8 0 0 0 1.791 1.812m.8-2.4a.465.465 0 1 1-.46.466.463.463 0 0 1 .46-.466"
        transform="translate(-4.791 -5.367)"
      />
      <path
        d="M19.515 0h-12v2.081l4.471 4.711v5.118l3.044-2.085V6.744l4.482-4.674zm-5.057 9.5-1.9 1.3V7h1.9zm.148-3.161h-2.242L8.309 2.07H18.7zm4.338-4.869H8.072V.6h10.872z"
        transform="translate(-1.515 3.56)"
      />
    </g>
  </svg>
);

export const WaterMeterSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g>
      <path d="M24 12A12 12 0 1 1 12 0a12 12 0 0 1 12 12" fill="#ff00b1" />
      <path
        d="M12.78 17.747s-2.207 3.147-2.22 4.4a.023.023 0 0 1-.007.013 2.224 2.224 0 1 0 4.447 0v-.013c-.02-1.253-2.22-4.4-2.22-4.4m1.56 4.407a.57.57 0 1 1-1.14 0v-.007a.57.57 0 1 1 1.14 0zm8.88-9.707v2.667h-1.9v.673h-1.84a7.032 7.032 0 0 1-4.367 4.587v-.714a6.333 6.333 0 1 0-4.667 0v.713a7.057 7.057 0 0 1-4.373-4.587h-1.84v-.673H2.34v-2.666h1.893v-.667h1.84a6.993 6.993 0 0 1 13.407 0h1.84v.667zm-15.775-.669h1.333v2.667H7.445zm3.667 2.667H9.778v-2.667h1.333zm2.333 0h-1.334v-2.667h1.333zm2.333 0h-1.333v-2.667h1.333zm2.333 0h-1.333v-2.667h1.333zm-7.667.917a1 1 0 1 1-1 1 1 1 0 0 1 1-1"
        transform="translate(-.78 -2.26)"
        fill="#f5f5f5"
      />
    </g>
  </svg>
);

function SystemElement({
  type,
  id,
  x,
  y,
  drag,
  disabled,
  zoomDelta,
  isSelected,
  setSelectedElement,
  onChange,
  hasShapeLimitError,
}) {
  var Element;
  var r = 12;
  switch (type) {
    case "controller":
      Element = <ControllerSVG />;
      break;
    case "water-supply":
      Element = hasShapeLimitError ? <WsNotEnoughSVG /> : <WaterSupplySVG />;
      break;
    case "valve-box":
      Element = <ValveBoxSVG />;
      break;
    case "water-tap-point":
      Element = <WaterTapSVG />;
      r = 9;
      break;
    case "fertilizer":
      Element = <FertilizerSVG />;
      break;
    case "air-compressor":
      Element = <AirCompressorSVG />;
      break;
    case "water-filter":
      Element = <WaterFilterSVG />;
      break;
    case "water-meter":
      Element = <WaterMeterSVG />;
      break;
    default:
      break;
  }

  return (
    <Draggable
      id={id}
      x={x}
      y={y}
      move={drag}
      onAfterMove={onChange}
      draggable={!disabled}
      cloneable={!disabled && type === "water-tap-point"}
      dragProps={id}
      onClick={() => {
        setSelectedElement(id);
      }}
    >
      <g
        className="element system-element"
        transform={`translate(${-pixelSizeByZoom(
          r,
          zoomDelta
        )},${-pixelSizeByZoom(r, zoomDelta)}) scale(${pixelSizeByZoom(
          1,
          zoomDelta
        )})`}
      >
        {isSelected ? (
          <circle stroke={"#000"} strokeWidth={2} cx={r} cy={r} r={r}></circle>
        ) : null}
        {Element}
      </g>
    </Draggable>
  );
}

let SystemElementWithState = ({ element, ...props }) => {
  const { uiState } = useStores();
  const planSizeValidation = usePlanSizeValidation();

  return (
    <SystemElement
      type={element.systemType}
      id={element.id}
      x={element.x}
      y={element.y}
      drag={element.drag}
      disabled={element.disabled || !uiState.planIsEditable}
      zoomDelta={uiState.zoomState.zoomDelta}
      setSelectedElement={uiState.setSelectedElement}
      isSelected={element.id === uiState.selectedElementId}
      onChange={
        uiState.reactions
          ? (params) => {
              uiState.reactions.onSystemElementChange(params);
              planSizeValidation();
            }
          : () => {}
      }
      hasShapeLimitError={element.hasShapeLimitError}
      {...props}
    />
  );
};

SystemElementWithState = observer(SystemElementWithState);
export default SystemElementWithState;
