import { extendObservable, observable, action } from "mobx";
import { v4 as uuid } from "uuid";

/**
 * Represents a system element object
 * @param {*} param0 - element json
 * @param {*} settingsState - state that contain a labels for UI
 * @param {*} param2 - additional UI params
 * contains
 * id - element identifier
 * systemType - "water-supply", "controller","valve-box", "fertilizer",
 *   "air-compressor" or a "water-filter"
 * x,y - point coordinates
 * recom - additional items by systemType
 * waterQuantity, waterQuality - only for water-supply
 * waterFilter, waterPressureSensor - only for water-filter
 * controllerType - only for controller
 * valveBoxType - only for valvebox
 * fertilizerType - only for fertilizerz
 * disabled - default is false,
 */
const systemElementFactory = (
  {
    id,
    x,
    y,
    systemType,
    waterQuality,
    recom,
    waterQuantity = 0,
    waterFilter,
    waterPressureSensor,
    controllerType,
    valveBoxType,
    fertilizerType,
    waterMeterType,
    disabled = false,
  } = {},
  settingsState
) => {
  let stateRecom = {};
  if (recom) {
    Object.keys(recom).forEach((key) => {
      stateRecom[key] = recomFactory(recom[key]);
    });
  }

  const state = observable({
    x: Math.round(x),
    y: Math.round(y),
    waterFilter,
    waterPressureSensor,
    waterQuality,
    controllerType,
    valveBoxType,
    fertilizerType,
    waterMeterType,
    recom: stateRecom,
  });

  const systemElement = observable({
    get type() {
      return "system-element";
    },
    systemType,
    id: id || "system-element-" + uuid(),
    get x() {
      return Math.round(state.x);
    },
    set x(x) {
      state.x = x;
    },
    get y() {
      return Math.round(state.y);
    },
    set y(y) {
      state.y = y;
    },
    get title() {
      const label = settingsState.texts.properties.systemElements;
      let title;
      switch (systemType) {
        case "water-supply":
          title = label.waterSupply.title;
          break;
        case "controller":
          title = label.controller.title;
          break;
        case "valve-box":
          title = label.valveBox.title;
          break;
        case "water-tap-point":
          title = label["water-tap-point"].title;
          break;
        case "fertilizer":
          title = label.fertilizer.title;
          break;
        case "air-compressor":
          title = label.airCompressor.title;
          break;
        case "water-filter":
          title = label.waterFilter.title;
          break;
        case "water-meter":
          title = label.waterMeter.title;
          break;
        default:
          break;
      }

      return title;
    },
    disabled,
    waterQuantity,
  });

  const getRecoms = (current, data) => {
    const currentController = data.find(({ value }) => value === current);

    let recoms = {};
    if (currentController?.recommendations && settingsState) {
      currentController.recommendations.forEach((alias) => {
        recoms[alias] = recomFactory({ ...settingsState.elements[alias] });
      });
    }
    return recoms;
  };

  const changeRecoms = () => {
    let res = {};
    switch (systemType) {
      case "water-supply":
        res = getRecoms(
          systemElement.waterQuality,
          systemElement.waterQualityData
        );
        break;
      case "controller":
        res = getRecoms(
          systemElement.controllerType,
          systemElement.controllerTypeData
        );
        break;
      case "valve-box":
        res = getRecoms(
          systemElement.valveBoxType,
          systemElement.valveBoxTypeData
        );
        break;
      case "water-filter":
        res = getRecoms(
          systemElement.waterFilter,
          systemElement.waterFilterData
        );
        break;
      default:
        break;
    }
    return res;
  };

  //getters
  extendObservable(systemElement, {
    get waterFilter() {
      return (
        state.waterFilter ||
        (this.waterFilterData.length > 0
          ? this.waterFilterData[0].value
          : undefined)
      );
    },
    get waterFilterData() {
      let data = [];
      if (settingsState) {
        settingsState.elements.filterMastervalve.forEach((item) => {
          data.push({
            label: item.label,
            value: item.value,
          });
        });
      }

      return data;
    },
    get waterPressureSensor() {
      return state.waterPressureSensor || "no-sensor";
    },
    get waterQuality() {
      let quality =
        state.waterQuality ||
        (this.waterQualityData.length > 0
          ? this.waterQualityData[0].value
          : undefined);

      return quality;
    },
    get recom() {
      if (Object.keys(state.recom).length === 0) {
        const recoms = changeRecoms();
        if (recoms && Object.keys(recoms).length > 0) {
          state.recom = recoms;
        }
      }
      return state.recom;
    },
    get waterQualityData() {
      let data = [];
      if (settingsState) {
        settingsState.elements.waterSupplyQuality.forEach((item) => {
          data.push({ ...item });
        });
      }

      return data;
    },
    get controllerType() {
      return (
        state.controllerType ||
        (this.controllerTypeData.length > 0
          ? this.controllerTypeData[0].value
          : undefined)
      );
    },
    get controllerTypeData() {
      let data = [];
      if (settingsState) {
        settingsState.elements.irrigationController.forEach((item) => {
          data.push({ ...item });
        });
      }

      return data;
    },
    get valveBoxType() {
      return (
        state.valveBoxType ||
        (this.valveBoxTypeData.length > 0
          ? this.valveBoxTypeData[0].value
          : undefined)
      );
    },
    get valveBoxTypeData() {
      let data = [];
      if (settingsState) {
        settingsState.elements.valveBox.forEach((item) => {
          data.push({ ...item });
        });
      }

      return data;
    },
    get fertilizerType() {
      return (
        state.fertilizerType || settingsState.elements.fertilizer?.[0]?.value
      );
    },
    get waterMeterType() {
      return (
        state.waterMeterType ||
        (this.waterMeterData.length > 0
          ? this.waterMeterData[0].value
          : undefined)
      );
    },
    get waterMeterData() {
      const data = [];
      if (settingsState) {
        settingsState.elements.waterMeter.forEach((item) =>
          data.push({ ...item })
        );
      }

      return data;
    },
    get deleteConfirmText() {
      const lables = settingsState.texts.tools;

      if (systemElement.systemType === "water-supply")
        return lables.waterSupply.delete;
      if (systemElement.systemType === "controller")
        return lables.controller.delete;
      if (systemElement.systemType === "valve-box")
        return lables.valveBox.delete;
      if (systemElement.systemType === "water-tap-point")
        return lables.waterTapPoint.delete;
      if (systemElement.systemType === "fertilizer")
        return lables.fertilizer.delete;
      if (systemElement.systemType === "air-compressor")
        return lables.airCompressor.delete;
      if (systemElement.systemType === "water-filter")
        return lables.waterFilter.delete;
      if (systemElement.systemType === "water-meter")
        return lables.waterMeter.delete;

      return null;
    },
    get toJSON() {
      const {
        id,
        x,
        y,
        type,
        systemType,
        title,
        waterQuality,
        recom,
        waterQuantity,
        waterFilter,
        waterPressureSensor,
        controllerType,
        valveBoxType,
        fertilizerType,
        waterMeterType,
        disabled,
      } = this;

      let recomRes = {};
      if (recom) {
        Object.keys(recom).forEach((key) => {
          recomRes[key] = recom[key].toJSON;
        });
      }

      return {
        id,
        x,
        y,
        systemType,
        type,
        title,
        waterQuality,
        recom: recomRes,
        waterQuantity,
        waterFilter,
        waterPressureSensor,
        controllerType,
        valveBoxType,
        fertilizerType,
        waterMeterType,
        disabled,
      };
    },
  });

  // actions
  extendObservable(systemElement, {
    drag: action((x, y) => {
      state.x = x;
      state.y = y;
    }),
    onDisable: action(() => {
      systemElement.disabled = !systemElement.disabled;
    }),
    сhangeWaterQuality: action((value) => {
      state.waterQuality = value;
      state.recom = changeRecoms();
    }),
    changeWaterQuantity: action((value) => {
      systemElement.waterQuantity = isNaN(value) ? 0 : value;
    }),
    changeWaterFilter: action((value) => {
      state.waterFilter = value;
    }),
    changeWaterPressureSensor: action((value) => {
      state.waterPressureSensor = value === true ? "sensor" : "no-sensor";
    }),
    changeControllerType: action((value) => {
      state.controllerType = value;
      state.recom = changeRecoms();
    }),
    changeValveBoxType: action((value) => {
      state.valveBoxType = value;
      state.recom = changeRecoms();
    }),
    changeFertilizerType: action((value) => {
      state.fertilizerType = value;
      state.recom = changeRecoms();
    }),
    changeWaterMeterType: action((value) => {
      state.waterMeterType = value;
    }),
  });

  return systemElement;
};

const recomFactory = ({ name, description, value, bomId }) => {
  const recom = observable({
    name,
    description,
    value,
    bomId,
    changeValue: action((val) => {
      recom.value = val;
    }),
    get toJSON() {
      const { name, description, value, bomId } = this;
      return {
        name,
        description,
        value,
        bomId,
      };
    },
  });
  return recom;
};

export default systemElementFactory;
