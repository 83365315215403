import { observer } from "mobx-react";
import React from "react";
import { useIntl } from "react-intl";

import ToolsItem from "./ToolsItem";
import ToolsBlock from "./ToolsBlock";

import { ReactComponent as ControllerSVG } from "./../../assets/controller-tool.svg";
import { ReactComponent as ValveBoxSVG } from "./../../assets/valve-box-tool.svg";
import { ReactComponent as WaterTapSVG } from "./../../assets/water-tap-tool.svg";
import { ReactComponent as WaterSupplySVG } from "./../../assets/water-tool.svg";
import { ReactComponent as FertilizerSVG } from "./../../assets/fertilizer-tool.svg";
import { ReactComponent as AirCompressorSVG } from "./../../assets/air-compressor-tool.svg";
import { ReactComponent as WaterFilterSVG } from "./../../assets/water-filter-tool.svg";
import { ReactComponent as WaterMeterSVG } from "./../../assets/water-meter-tool.svg";
import useStores from "../../hooks/useStores";

function SystemElementTools({ selectedTool, setSelectedTool, labels }) {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <ToolsBlock
        label={labels ? formatMessage({ id: labels.blocks.mandatory }) : null}
      >
        <ToolsItem
          icon={<WaterSupplySVG />}
          type="water-supply"
          isActive={selectedTool === "water-supply"}
          onClick={() => {
            setSelectedTool("water-supply");
          }}
          tooltip={
            labels ? formatMessage({ id: labels.waterSupply.tooltip }) : null
          }
        />
        <ToolsItem
          icon={<ControllerSVG />}
          type="controller"
          isActive={selectedTool === "controller"}
          onClick={() => {
            setSelectedTool("controller");
          }}
          tooltip={
            labels ? formatMessage({ id: labels.controller.tooltip }) : null
          }
        />
        <ToolsItem
          icon={<ValveBoxSVG />}
          type="valve-box"
          isActive={selectedTool === "valve-box"}
          onClick={() => {
            setSelectedTool("valve-box");
          }}
          tooltip={
            labels ? formatMessage({ id: labels.valveBox.tooltip }) : null
          }
        />
      </ToolsBlock>
      <ToolsBlock
        label={labels ? formatMessage({ id: labels.blocks.optional }) : null}
        hasLine={true}
      >
        <ToolsItem
          icon={<WaterMeterSVG />}
          type="water-meter"
          isActive={selectedTool === "water-meter"}
          onClick={() => {
            setSelectedTool("water-meter");
          }}
          tooltip={
            labels ? formatMessage({ id: labels.waterMeter.tooltip }) : null
          }
        />
        <ToolsItem
          icon={<WaterFilterSVG />}
          type="water-filter"
          isActive={selectedTool === "water-filter"}
          onClick={() => {
            setSelectedTool("water-filter");
          }}
          tooltip={
            labels ? formatMessage({ id: labels.waterFilter.tooltip }) : null
          }
        />
        <ToolsItem
          icon={<WaterTapSVG />}
          type="water-tap-point"
          isActive={selectedTool === "water-tap-point"}
          onClick={() => {
            setSelectedTool("water-tap-point");
          }}
          tooltip={
            labels ? formatMessage({ id: labels.waterTapPoint.tooltip }) : null
          }
        />
        <ToolsItem
          icon={<FertilizerSVG />}
          type="fertilizer"
          isActive={selectedTool === "fertilizer"}
          onClick={() => {
            setSelectedTool("fertilizer");
          }}
          tooltip={
            labels ? formatMessage({ id: labels.fertilizer.tooltip }) : null
          }
        />
        <ToolsItem
          icon={<AirCompressorSVG />}
          type="air-compressor"
          isActive={selectedTool === "air-compressor"}
          onClick={() => {
            setSelectedTool("air-compressor");
          }}
          tooltip={
            labels ? formatMessage({ id: labels.airCompressor.tooltip }) : null
          }
        />
      </ToolsBlock>
    </React.Fragment>
  );
}

let SystemElementToolsWithState = () => {
  const { uiState } = useStores();
  return (
    <SystemElementTools
      selectedTool={uiState.selectedTool}
      setSelectedTool={uiState.setSelectedTool}
      labels={uiState.settingsState ? uiState.settingsState.texts.tools : null}
    />
  );
};

SystemElementToolsWithState = observer(SystemElementToolsWithState);
export default SystemElementToolsWithState;
