import React from "react";
import { observer } from "mobx-react";

import { toJS, action } from "mobx";
import { useDraggable } from "react-draggable-hoc";

import { getSvgPoint, pixelSizeByZoom } from "@dvsproj/ipat-core/planUtils";
import {
  lineLength,
  cutLineFromSegments,
} from "@dvsproj/ipat-core/geometryUtils";
import { toFixedPrecision } from "@dvsproj/ipat-core/formatter";

import PipePoint from "./PipePoint";
import PipeLine from "./PipeLine";
import Trench from "./Trench";
import PipeContext from "./PipeContext";
import TrenchViewPopup from "./TrenchViewPopup";
import DefaultSVGFilters from "../DefaultSVGFilters";
import { useIntl } from "react-intl";
import usePlanSizeValidation from "../../hooks/usePlanSizeValidation";
import useStores from "../../hooks/useStores";

const Pipe = observer(
  ({
    pipe,
    trenches,
    trenchesThreshold,
    selectedTrenchId,
    setSelectedTrenchId,
    selectedColor,
    ...props
  }) => {
    const { lines, points, color, lineOptions, hasError, hidden } = pipe;
    const trenchesHasLineId = (lineId) => {
      const result = trenches?.filter(
        (t) => t.pipes.map((el) => el.id).find((id) => id === lineId) != null
      );
      return result;
    };

    const visibleSegments = (line, lineTrenches) => {
      if (line.startPoint == null || line.endPoint == null) return;

      let segments = [{ start: line.startPoint, end: line.endPoint }];
      if (lineTrenches) {
        let filteredTrenches = lineTrenches.filter(
          (el) => el.pipesForPopup.length > 1
        );
        for (let trench of filteredTrenches) {
          if (trench.isValid) {
            segments = cutLineFromSegments(segments, trench, trenchesThreshold);
          }
        }
      }
      return segments;
    };

    const hasSelected = (arr) => {
      let res = false;
      let i = 0;
      while (!res && i < arr.length) {
        if (
          arr[i].id === props.selectedElementId //||
          //arr[i].id === props.hoveredElementId
        )
          res = true;
        i++;
      }
      return res;
    };

    const selected = hasSelected(lines) || hasSelected(points);

    return (
      <g id={pipe.id} datatype={pipe.lineType}>
        {lines != null &&
          lines.map((line) => {
            let allTrenches = selected ? null : trenchesHasLineId(line.id);
            let segments = visibleSegments(line, allTrenches);
            return (
              <g key={line.id}>
                {segments?.length > 0 ? (
                  <PipeLine
                    element={line}
                    segments={segments}
                    color={color}
                    lineOptions={lineOptions}
                    hidden={hidden}
                    addPoint={pipe.addPoint}
                    hasError={hasError}
                    isRoundLine={props.useDefaultStyles}
                    {...props}
                  />
                ) : null}
                {allTrenches != null
                  ? allTrenches
                      .filter(
                        (t) =>
                          t.pipes[0].id === line.id &&
                          t.pipesForPopup.length > 1 &&
                          t.colors.indexOf(selectedColor) < 0
                      )
                      .map((trench) => {
                        return (
                          trench && (
                            <Trench
                              key={trench.id}
                              selectedTrenchId={selectedTrenchId}
                              setSelectedTrenchId={setSelectedTrenchId}
                              element={trench}
                              lineVisible={true}
                              countVisible={false}
                              {...props}
                            />
                          )
                        );
                      })
                  : null}
              </g>
            );
          })}
        {points != null &&
          points
            .sort((a, b) => {
              const sortByPointType = (type) => {
                if (a.pointType === type || b.pointType === type) {
                  return a.pointType === type ? -1 : 1;
                }
                return 0;
              };

              let result = sortByPointType("start-point");
              if (result !== 0) return result;

              // selected
              if (
                props.selectedElementId &&
                (a.id === props.selectedElementId ||
                  b.id === props.selectedElementId)
              ) {
                return a.id === props.selectedElementId ? 1 : -1;
              }

              result = sortByPointType("raised-bed-point");
              if (result !== 0) return result;

              result = sortByPointType("rzws-point");
              if (result !== 0) return result;

              result = sortByPointType("water-meter");
              if (result !== 0) return result;

              result = sortByPointType("water-filter");
              if (result !== 0) return result;

              result = sortByPointType("fertilizer");
              if (result !== 0) return result;

              result = sortByPointType("air-compressor");
              if (result !== 0) return result;

              result = sortByPointType("water-tap-point");
              if (result !== 0) return result;

              result = sortByPointType("water-supply");
              if (result !== 0) return result;

              return a.pointType === "sprinkler-point" ||
                a.pointType === "t-point" ||
                a.pointType === "l-point"
                ? -1
                : b.pointType === "sprinkler-point" ||
                  b.pointType === "t-point" ||
                  b.pointType === "l-point"
                ? 1
                : 0;
            })
            .map((point) => {
              if (
                props.useDefaultStyles &&
                !(
                  point.isSystemElementPoint ||
                  point.isDriplinePoint ||
                  point.isWaterTapPoint ||
                  point.isRzwsPoint ||
                  point.isRaisedBedPoint
                )
              ) {
                return undefined;
              } else {
                return (
                  <PipePoint
                    key={point.id}
                    element={point}
                    color={color}
                    hidden={hidden}
                    {...props}
                  />
                );
              }
            })}
      </g>
    );
  }
);

const Pipes = ({
  selectedTool,
  addPoint = () => {},
  addPipe = () => {},
  findElementById = () => {},
  width,
  height,
  viewbox,
  pipelines,
  trenches,
  zoomDelta,
  hiddenPipeColors = [],
  draggable,
  planIsEditable,
  setSelectedElement,
  selectedElementId,
  hoveredElementId,
  onSave,
  addPipePoint,
  selectedPipePointId,
  setSelectedPipePointId,
  showAlert,
  confirmLabels,
  scale,
  onRemoveElement,
  removeElementById,
  changeDrawingPipeLengthInPx = () => {},
  setHoveredElementId = () => {},
  startDrawing = () => {},
  stopDrawing = () => {},
  cannotAddedAlert = () => {},
  useDefaultStyles = true,
  grayscale = false,
  recalculateTrenches = () => {},
}) => {
  const { formatNumber } = useIntl();

  const trenchesThreshold = pixelSizeByZoom(10, zoomDelta);

  const [mouseCoord, changeMouseCoord] = React.useState();
  const [selectedTrenchId, setSelectedTrenchId] = React.useState();

  const ref = React.useRef();
  const pipeContext = React.useRef({
    mode: "chain",
  });

  const pipelineEdited = !draggable && selectedTool === "pipeline-add";

  const selectPoint = React.useCallback(
    (id) => {
      let saved = false;
      if (selectedTool !== "pipeline-add") {
        changeMouseCoord(undefined);
        return;
      }
      const p = findElementById(id);
      let selectedPointId = selectedPipePointId
        ? selectedPipePointId
        : p != null && p.selectableByPipe
        ? id
        : undefined;
      if (p && !p.isStartPoint && selectedPointId && selectedPointId !== id) {
        const pipeline = p.pipelines ? p.pipelines[0] : undefined;
        let text = confirmLabels.waterQuantityWarningText;
        const req =
          pipeline && pipeline.waterQuantity
            ? formatNumber(
                toFixedPrecision(pipeline.waterQuantity / 1000, 2) * 1
              )
            : null;

        const prev = findElementById(selectedPointId);
        const rem =
          prev &&
          !prev.isStartPoint &&
          prev.pipelines &&
          prev.pipelines[0] &&
          prev.pipelines[0].waterQuantity
            ? formatNumber(
                toFixedPrecision(
                  (prev.pipelines[0].shapeLimit -
                    prev.pipelines[0].waterQuantity) /
                    1000,
                  2
                )
              )
            : null;
        if (
          req &&
          rem &&
          pipeline.id !== prev.pipelines[0].id &&
          req > rem &&
          text
        ) {
          text = text.replace("&req", req);
          text = text.replace("&rem", rem);
          cannotAddedAlert(() =>
            showAlert({
              title: confirmLabels.warningTitle,
              description: text,
            })
          );
          setSelectedElement(selectedPointId);
          changeMouseCoord(undefined);
          return "rejected";
        }
      }
      if (p != null && p.selectableByPipe && selectedPointId !== id) {
        addPipe({ start: selectedPointId, end: id });
        onSave();
        saved = true;
        setSelectedPipePointId(undefined);

        selectedPointId = p.selectableByPipe ? id : undefined;
      } else {
        changeMouseCoord(undefined);
      }

      if (
        id == null ||
        (p != null &&
          selectedPipePointId != null &&
          (selectedPipePointId === id || p.isStartPoint))
      ) {
        if (p) {
          p.lazyFixPointType();
          onSave();
        }
        selectedPointId = undefined;
      }

      setSelectedPipePointId(selectedPointId);
      setSelectedElement(id);

      if (selectedPointId) {
        startDrawing();
      }

      if (selectedPointId == null && mouseCoord != null) {
        changeMouseCoord(undefined);
        stopDrawing();
      }

      return saved;
    },
    [
      selectedTool,
      findElementById,
      selectedPipePointId,
      setSelectedPipePointId,
      mouseCoord,
      addPipe,
      onSave,
      setSelectedElement,
      showAlert,
      cannotAddedAlert,
      confirmLabels,
      startDrawing,
      stopDrawing,
      formatNumber,
    ]
  );

  const onMouseMove = React.useCallback(
    (e) => {
      if (
        (selectedTool && selectedTool !== "pipeline-add") ||
        selectedPipePointId == null
      ) {
        if (mouseCoord != null) changeMouseCoord(undefined);
        return;
      }
      const svgP = getSvgPoint(e, ref.current);
      changeMouseCoord({ x: svgP.x, y: svgP.y });
    },
    [selectedTool, selectedPipePointId, mouseCoord]
  );

  React.useEffect(() => {
    let newVal = 0;
    if (selectedPipePointId != null && mouseCoord != null) {
      const p = findElementById(selectedPipePointId);
      if (p) {
        newVal = lineLength(p, mouseCoord, null);
      }
    }
    changeDrawingPipeLengthInPx(newVal);
  }, [
    findElementById,
    changeDrawingPipeLengthInPx,
    mouseCoord,
    selectedPipePointId,
  ]);

  const onMouseUp = React.useCallback(
    (e) => {
      if (
        (selectedTool && selectedTool !== "pipeline-add") ||
        !e ||
        !e.target ||
        !(
          (e.target &&
            (e.target.id === "pipes-svg" || e.target.id === "mouseLine")) ||
          (e.target.parentNode &&
            (e.target.parentNode.id === "pipes-svg" ||
              e.target.parentNode.id === "mouseLine"))
        )
      ) {
        return false;
      }

      if (selectedPipePointId == null) {
        if (mouseCoord != null) {
          changeMouseCoord(undefined);
        }
      } else {
        const point = addPoint(e, ref.current);
        if (point) {
          selectPoint(point.id);
        }
      }
    },
    [addPoint, mouseCoord, selectPoint, selectedPipePointId, selectedTool]
  );

  useDraggable(ref, {
    disabled: !pipelineEdited,
    dragProps: "container",
  });

  React.useEffect(() => {
    if (
      (!selectedTool || selectedTool !== "pipeline-add") &&
      (mouseCoord != null || selectedPipePointId)
    ) {
      if (selectedPipePointId) {
        setSelectedElement(selectedPipePointId);
        const point = findElementById(selectedPipePointId);
        if (point) {
          point.lazyFixPointType();
          onSave();
        }
        setSelectedPipePointId(undefined);
      }

      if (mouseCoord != null) {
        changeMouseCoord(undefined);
      }
    }
  }, [
    mouseCoord,
    selectedPipePointId,
    selectedTool,
    setSelectedPipePointId,
    setSelectedElement,
    findElementById,
    onSave,
  ]);

  React.useEffect(() => {
    if (selectedTool !== "pipeline-add") {
      stopDrawing();
    }
  }, [selectedTool, stopDrawing]);

  React.useEffect(() => {
    recalculateTrenches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomDelta]);

  const selectedColor = React.useMemo(() => {
    const hasSelected = (arr) => {
      let res = false;
      let i = 0;
      while (!res && i < arr.length) {
        if (arr[i].id === selectedElementId)
          // || arr[i].id === hoveredElementId)
          res = true;
        i++;
      }
      return res;
    };
    let selectedPipeline = pipelines?.find(
      (pipeline) => hasSelected(pipeline.lines) || hasSelected(pipeline.points)
    );
    return selectedPipeline != null ? selectedPipeline.color : null;
  }, [pipelines, selectedElementId]);

  const pipesContainer = React.useMemo(
    () =>
      pipelines
        ?.filter(({ color }) => hiddenPipeColors.indexOf(color) < 0)
        .map((pipeline) => (
          <Pipe
            key={pipeline.id}
            pipe={pipeline}
            selectPoint={selectPoint}
            planIsEditable={planIsEditable}
            setSelectedElement={setSelectedElement}
            selectedTool={selectedTool}
            selectedElementId={selectedElementId}
            selectedColor={selectedColor}
            hoveredElementId={hoveredElementId}
            selectedPipePointId={selectedPipePointId}
            setHoveredElementId={setHoveredElementId}
            zoomDelta={zoomDelta}
            draggable={draggable}
            onSave={onSave}
            getSvgPoint={(e) => getSvgPoint(e, ref.current)}
            addPipePoint={addPipePoint}
            clearMouseLine={() => {
              changeMouseCoord(undefined);
            }}
            cannotAddedAlert={cannotAddedAlert}
            scale={scale}
            onRemoveElement={onRemoveElement}
            removeElementById={removeElementById}
            useDefaultStyles={useDefaultStyles}
            trenches={trenches}
            trenchesThreshold={trenchesThreshold}
          />
        )),
    [
      addPipePoint,
      draggable,
      trenches,
      hiddenPipeColors,
      onSave,
      pipelines,
      planIsEditable,
      selectPoint,
      selectedElementId,
      selectedColor,
      hoveredElementId,
      selectedPipePointId,
      selectedTool,
      setSelectedElement,
      setHoveredElementId,
      zoomDelta,
      onRemoveElement,
      removeElementById,
      scale,
      useDefaultStyles,
      cannotAddedAlert,
      trenchesThreshold,
    ]
  );

  const trenchContainer = React.useMemo(() => {
    let result = [];
    if (trenches && trenches.length > 0) {
      trenches.forEach((element) => {
        result.push(
          <Trench
            key={element.id}
            element={element}
            selectedTrenchId={selectedTrenchId}
            zoomDelta={zoomDelta}
            scale={scale}
            useDefaultStyles={useDefaultStyles}
            lineVisible={false}
            countVisible={true}
            setSelectedTrenchId={setSelectedTrenchId}
          />
        );
      });
    }
    return result;
  }, [
    trenches,
    zoomDelta,
    scale,
    useDefaultStyles,
    selectedTrenchId,
    setSelectedTrenchId,
  ]);

  const trenchView = React.useMemo(() => {
    let trench =
      trenches && trenches.length > 0
        ? trenches.find((el) => el.id === selectedTrenchId)
        : null;
    if (trench) {
      return (
        <TrenchViewPopup
          element={trench}
          onClose={() => {
            setSelectedTrenchId(null);
          }}
          setSelectedElement={setSelectedElement}
          selectedElementId={selectedElementId}
          zoomDelta={zoomDelta}
          scale={scale}
        />
      );
    }
    return null;
  }, [
    trenches,
    selectedTrenchId,
    setSelectedTrenchId,
    selectedElementId,
    setSelectedElement,
    zoomDelta,
    scale,
  ]);

  const { mouseLinePath, mouseColor } = React.useMemo(() => {
    const p = findElementById(selectedPipePointId);

    const m = mouseCoord;
    let color = "#000";

    let path = p && m ? `M ${p.x} ${p.y} L ${m.x} ${m.y}` : null;
    if (p && m && pipelines && pipelines.length > 0) {
      const line = pipelines.find(
        (l) => l.points && l.points.find(({ id }) => id === p.id)
      );
      color = line ? line.color : color;
    }

    return { mouseLinePath: path, mouseColor: color };
  }, [findElementById, mouseCoord, pipelines, selectedPipePointId]);

  return (
    <PipeContext.Provider value={pipeContext.current}>
      <svg
        id="pipes-svg"
        style={{ zIndex: 101 }}
        viewBox={`${viewbox.x} ${viewbox.y} ${viewbox.w} ${viewbox.h}`}
        width={width}
        height={height}
        ref={ref}
        onTouchMove={pipelineEdited ? onMouseMove : undefined}
        onMouseMove={pipelineEdited ? onMouseMove : undefined}
        onMouseUp={pipelineEdited ? onMouseUp : undefined}
        onTouchEnd={pipelineEdited ? onMouseUp : undefined}
      >
        <DefaultSVGFilters />
        <g filter={grayscale ? "url(#grayscale)" : ""}>
          {mouseLinePath ? (
            <React.Fragment>
              <path
                id="mouseLineBorder"
                d={mouseLinePath}
                stroke="#000"
                strokeWidth={pixelSizeByZoom(9, zoomDelta)}
                fill="none"
              />
              <path
                id="mouseLine"
                d={mouseLinePath}
                stroke={mouseColor}
                strokeWidth={pixelSizeByZoom(6, zoomDelta)}
                fill="none"
              />
            </React.Fragment>
          ) : null}
          {pipesContainer}
          {trenchContainer}
          {trenchView}
        </g>
      </svg>
    </PipeContext.Provider>
  );
};

let PipesWithState = ({
  viewbox,
  pipelines,
  pipesModifiedAt,
  scale,
  ...props
}) => {
  const { uiState } = useStores();
  const { formatMessage } = useIntl();
  const planSizeValidation = usePlanSizeValidation();

  const cannotAddedAlert = (fn = () => {}) => {
    if (!uiState.plan.pipelineError) {
      return fn();
    } else {
      uiState.showAlert({
        title: formatMessage({ id: uiState.settingsState.dialog.warningTitle }),
        description: formatMessage({ id: uiState.plan.pipelineError }),
      });
    }
    return null;
  };

  React.useEffect(() => {
    uiState.reactions.onPipesModifiedAtChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipesModifiedAt]);

  return (
    <Pipes
      {...props}
      viewbox={toJS(viewbox)}
      pipelines={pipelines}
      selectedTool={uiState.selectedTool}
      selectedOtherTool={uiState.selectedOtherTool}
      zoomDelta={uiState.zoomState.zoomDelta}
      selectedPipePointId={
        uiState.plan ? uiState.plan.selectedPointId : undefined
      }
      setSelectedPipePointId={
        uiState.plan
          ? action((id) => (uiState.plan.selectedPointId = id))
          : undefined
      }
      selectedElementId={uiState.selectedElementId}
      setSelectedElement={action((id) => {
        uiState.selectedElementId = id;
      })}
      hoveredElementId={uiState.hoveredElementId}
      setHoveredElementId={uiState.setHoveredElementId}
      addPipe={
        uiState.plan && uiState.planIsEditable
          ? (params) => {
              cannotAddedAlert(() => {
                uiState.plan.addPipe(params);
                uiState.showCircuitCrowdedWarning();
              });
            }
          : () => {}
      }
      addPoint={
        uiState.plan && uiState.planIsEditable
          ? (e, svg) => {
              let point = getSvgPoint(e, svg);
              if (point) {
                return uiState.plan.addPipePoint(
                  { x: point.x, y: point.y },
                  null
                );
              }
              return null;
            }
          : () => {}
      }
      hiddenPipeColors={
        uiState.hiddenPipeColors ? uiState.hiddenPipeColors.slice() : []
      }
      findElementById={uiState.plan ? uiState.plan.findById : () => {}}
      draggable={uiState.selectedOtherTool === "draggable"}
      planIsEditable={uiState.planIsEditable}
      onSave={
        uiState.planIsEditable && uiState.reactions
          ? (params) => {
              uiState.reactions.onPipelineChange(params);
              planSizeValidation();
            }
          : () => {}
      }
      addPipePoint={(params, id) => {
        return uiState.plan && uiState.planIsEditable
          ? cannotAddedAlert(() => {
              return uiState.plan.addPipePoint(params, id);
            })
          : () => {};
      }}
      showAlert={uiState.showAlert}
      cannotAddedAlert={cannotAddedAlert}
      confirmLabels={uiState.settingsState.dialog}
      scale={scale ? scale : uiState.plan ? uiState.plan.scale : null}
      onRemoveElement={uiState.onRemoveElement}
      removeElementById={
        uiState.plan ? uiState.plan.removeElementById : () => {}
      }
      startDrawing={action(() => {
        uiState.plan.unconnectablePipelines(uiState.selectedElement);
        uiState.plan.setPipelineHasDrawing(true);
      })}
      stopDrawing={action(() => {
        uiState.plan.unconnectablePipelines(undefined);
        uiState.plan.setPipelineHasDrawing(false);
      })}
      changeDrawingPipeLengthInPx={
        uiState
          ? (val) => {
              uiState.drawingPipeLengthInPx = val;
            }
          : () => {}
      }
      textsVisibility={uiState.textsVisibility}
      useDefaultStyles={uiState.useDefaultStyles}
      grayscale={uiState.grayscale.pipeline}
      recalculateTrenches={
        uiState.plan
          ? () => {
              uiState.plan.recalculateTrenches(
                uiState.hiddenPipeColors,
                uiState.zoomState.zoomDelta
              );
            }
          : () => {}
      }
    />
  );
};

PipesWithState = observer(PipesWithState);

export { PipesWithState as Pipes };
export default PipesWithState;
