import Header from "./components/header/Header";
import Tools from "./components/tools/Tools";
import Scale from "./components/Scale";
import IrrigationPlan from "./components/IrrigationPlan";
import RightSide from "./components/right/RightSide";
import CalculationPopup from "./components/popups/CalculationPopup";
import LoginPopup from "./components/popups/LoginPopup";
import WaterSupplyVideoPopup from "./components/popups/AboutWaterSupplyPopup";
import SavePopup from "./components/popups/SavePopup";
import TooltipContent from "./components/tooltip/TooltipContent";
import SprinklersCoverageLegend from "./components/sprinklers/SprinklersCoverageLegend";
import SensorCoverageLegend from "./components/sensor/SensorCoverageLegend";
import PrecipitationConfigPopup from "./components/popups/PrecipitationConfigPopup";
import Feedback from "./components/Feedback";
import WindowDialog from "./components/popups/WindowDialog";
import PlanSettingsStep from "./components/popups/plan/PlanSettingsStep";
import SmartGarden from "./components/sensor/SmartGarden";
import BomPage from "./components/bom/BomPage";
import { FunctionalKeysObserverComponent } from "./components/simpleComponents/FunctionalKeysObserverComponent";
import PlanAssessment from "./components/planAssessment/PlanAssessment";

function App() {
  return (
      <FunctionalKeysObserverComponent>
        <div id="dvs-plan">
          <Header />
          <main>
            <SprinklersCoverageLegend />
            <SensorCoverageLegend />
            <Tools />
            <Feedback />
            <RightSide />
            <Scale />
            <IrrigationPlan />
            <BomPage />
            <SmartGarden />
            <CalculationPopup />
          </main>
          <PlanAssessment />
          {/* <Footer /> */}
          <PrecipitationConfigPopup />
          <PlanSettingsStep />
          <LoginPopup />
          <WindowDialog />
          <WaterSupplyVideoPopup />
          <SavePopup />
          <TooltipContent />
        </div>
      </FunctionalKeysObserverComponent>
  );
}
export default App;


