const { generateElementPath, rectangleCorners } = require("./areaUtils");

/**
 * Used for calculate a constant size of elements on UI ignoring zoom
 * @param {*} px
 * @param {*} zoomDelta
 */
const pixelSizeByZoom = (px, zoomDelta) => {
  return zoomDelta ? px * zoomDelta : px;
};

const generateGrid = (scale, zoomDelta) => {
  const sizesInM = [0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 25, 50, 100, 200, 400];
  //const minSizeInPx = 40;
  const maxSizeInPx = 93;

  let k = 0;
  let gridSize = 0;
  let originalSize = 0;

  let i = 0;
  do {
    gridSize = sizeInPixelByMeters(sizesInM[i], scale);
    originalSize = gridSize / zoomDelta;
    if (originalSize < maxSizeInPx) {
      k = i;
    }
    i++;
  } while (i < sizesInM.length);

  gridSize = sizeInPixelByMeters(sizesInM[k], scale);
  originalSize = gridSize / zoomDelta;

  return {
    size: gridSize,
    originalSize: originalSize,
    value: sizesInM[k],
    weight: pixelSizeByZoom(0.3, zoomDelta),
  };
};

/**
 * Convert size from meters to pixels
 * @param {*} distance
 * @param {*} scale
 * scale * distance (in 10cm) = count (in px)
 */
const sizeInPixelByMeters = (distance, scale) => {
  return scale ? distance * scale : distance;
};

/**
 * Convert size from pixels to meters
 * @param {*} px
 * @param {*} scale - plan scale
 */
const sizeInMetersByPixel = (px, scale) => {
  return scale ? px / scale : px;
};

/**
 * Helper function by calculate svg container view box
 * @param {*} containerWidth - svg width
 * @param {*} containerHeight - svg height
 * @param {*} zoom - zoom value
 * @param {*} zoomStep - zoom step, default value is (plan background width / 100)
 */
const getSizeByZoom = (containerWidth, containerHeight, zoom, zoomStep) => {
  const w = containerWidth + zoom * zoomStep;
  const h = containerWidth ? (w * containerHeight) / containerWidth : 0;
  return {
    w,
    h,
  };
};

/**
 * Helper function, used to calculate zoom value by plan scale
 * @param {*} scale - plan scale value
 * @param {*} zoomStep - zoom step, default value is (plan background width / 100)
 * @param {*} width
 */
const getZoomByScale = (scale, zoomStep, width) => {
  return Math.round((width / scale - width) / zoomStep, 2);
};

/**
 * Calculate SVG coordinates by mouse event
 * @param {*} event
 * @param {*} svg
 */
const getSvgPoint = (event, svg) => {
  if (event && svg) {
    const x =
      typeof event.clientX === "undefined"
        ? event.changedTouches[0].clientX
        : event.clientX;
    const y =
      typeof event.clientY === "undefined"
        ? event.changedTouches[0].clientY
        : event.clientY;

    let pt = svg.createSVGPoint();
    pt.x = x;
    pt.y = y;

    return pt.matrixTransform(svg.getScreenCTM().inverse());
  }
  return null;
};

/**
 * Converter of the plan from one measurement system to another
 * @param {*} plan - plan json
 * @param {*} unit - ["pixel", "10cm"]
 */
const convertPlanJSON = (plan, unit) => {
  if (
    unit == null ||
    plan == null ||
    ["pixel", "10cm"].indexOf(plan.unit) < 0 ||
    ["pixel", "10cm"].indexOf(unit) < 0 ||
    plan.unit === unit ||
    plan.scale == null
  ) {
    return plan;
  }
  const {
    sprinklerSetType,
    scale,
    background,
    opacity,
    elements,
    offsetX,
    offsetY,
    editable,
    bomItems,
    bomType,
    showWaterSupplyVideo,
    planningTime,
    version,
  } = plan;

  const converter =
    unit !== "pixel"
      ? (val) => {
          return val != null ? sizeInMetersByPixel(val * 10, scale) : undefined;
        }
      : (val) => {
          return val != null ? sizeInPixelByMeters(val / 10, scale) : undefined;
        };

  let convertedElements = convertPlanElements(elements, converter);

  const result = {
    sprinklerSetType,
    scale,
    opacity,
    editable,
    elements: convertedElements,
    unit,
    offsetX: converter(offsetX),
    offsetY: converter(offsetY),
    bomItems,
    bomType,
    showWaterSupplyVideo,
    planningTime,
    version,
  };
  if (background) {
    result.background = {
      ...background,
      width: converter(background.width),
      height: converter(background.height),
    };
  }
  return result;
};

/**
 *
 * @param {*} plan
 * @param {*} koef
 */
const scalePlan = (plan, koef) => {
  if (plan == null || koef == null || koef <= 0) {
    return plan;
  }

  const { scale, background, elements, offsetX, offsetY } = plan;

  const converter = (val) => {
    return val != null ? val * koef : undefined;
  };

  let convertedElements = convertPlanElements(elements, converter);

  const result = {
    ...plan,
    scale: converter(scale),
    elements: convertedElements,
    offsetX: converter(offsetX),
    offsetY: converter(offsetY),
  };
  if (background) {
    result.background = {
      ...background,
      width: converter(background.width),
      height: converter(background.height),
    };
  }
  return result;
};

const convertPlanElements = (elements, converter = () => {}) => {
  let convertedElements = [];
  if (elements != null) {
    elements.forEach((el) => {
      switch (el.type) {
        case "area": {
          const areaPoints =
            el.points && el.points.length > 0
              ? el.points.map((point) => {
                  return {
                    ...point,
                    x: converter(point.x),
                    y: converter(point.y),
                    type: point.type,
                  };
                })
              : [];
          let newElem = {
            ...el,
            x: el.x ? converter(el.x) : null,
            y: el.y ? converter(el.y) : null,
            width: el.width ? converter(el.width) : null,
            height: el.height ? converter(el.height) : null,
            color: el.color,
            points: areaPoints,
            size: el.size,
            pointsCenter: el.pointsCenter
              ? {
                  x: converter(el.pointsCenter.x),
                  y: converter(el.pointsCenter.y),
                }
              : null,
          };
          newElem.path = generateElementPath(newElem);
          convertedElements.push(newElem);
          break;
        }
        case "sprinkler":
          convertedElements.push({
            ...el,
            type: el.type,
            nozzleType: el.nozzleType,
            x: el.x ? converter(el.x) : null,
            y: el.y ? converter(el.y) : null,
            circleRadius: el.circleRadius ? converter(el.circleRadius) : null,
            rectHeight: el.rectHeight ? converter(el.rectHeight) : null,
            rectWidth: el.rectWidth ? converter(el.rectWidth) : null,
            startAngle: el.startAngle,
            circleSectorAngle: el.circleSectorAngle,
          });
          break;
        case "sensor":
        case "system-element":
        case "pipeline-point":
          convertedElements.push({
            ...el,
            x: el.x ? converter(el.x) : null,
            y: el.y ? converter(el.y) : null,
          });
          break;
        case "rzws":
        case "raised-bed":
          convertedElements.push({
            ...el.toJSON,
            type: el.type,
            x: el.x ? converter(el.x) : null,
            y: el.y ? converter(el.y) : null,
          });
          break;
        case "irrigationValveCable":
        case "waterMeterCable": {
          const cablePoints =
            el.points && el.points.length > 0
              ? el.points.map((point) => {
                  return {
                    ...point,
                    x: point.x ? converter(point.x) : null,
                    y: point.y ? converter(point.y) : null,
                  };
                })
              : [];
          convertedElements.push({
            ...el,
            //distance: converter(el.distance),
            points: cablePoints,
          });
          break;
        }
        case "pipe":
          convertedElements.push(el);
          break;
        default:
          convertedElements.push(el);
          break;
      }
    });
  }
  return convertedElements;
};

/**
 * Offsets every point in the plan by the provided offsets
 * instead of the plan offsets
 * @param {*} plan
 * @param {*} offsetX
 * @param {*} offsetY
 */
const offsetPlan = (plan, offsetX = 0, offsetY = 0) => {
  if (plan == null || (plan.offsetX === offsetX && plan.offsetY === offsetY)) {
    return plan;
  }

  const convertX = (x) =>
    x != null ? x + (plan.offsetX || 0) - offsetX : undefined;
  const convertY = (y) =>
    y != null ? y + (plan.offsetY || 0) - offsetY : undefined;

  const elements = plan.elements.map((el) => {
    switch (el.type) {
      case "area": {
        const points =
          el.points && el.points.length > 0
            ? el.points.map((point) => {
                return {
                  ...point,
                  x: convertX(point.x),
                  y: convertY(point.y),
                };
              })
            : [];
        return {
          ...el,
          x: convertX(el.x),
          y: convertY(el.y),
          points,
        };
      }
      case "sensor":
      case "sprinkler":
      case "system-element":
      case "pipeline-point":
        return {
          ...el,
          x: convertX(el.x),
          y: convertY(el.y),
        };
      default:
        return {
          ...el,
        };
    }
  });

  return {
    ...plan,
    elements,
    offsetX,
    offsetY,
  };
};

const svgTextCondensed = {
  transform: "scale(.85 1)",
  style: {
    fontStretch: "normal",
  },
};

const getOffsetsAndSizeByElements = (
  elements,
  padding = 0,
  gridSize = 0,
  uncutBackground = false
) => {
  const updateFromPoint = (r, x, y) => {
    if (r.minX == null || r.minX > x) r.minX = x;
    if (r.maxX == null || r.maxX < x) r.maxX = x;
    if (r.minY == null || r.minY > y) r.minY = y;
    if (r.maxY == null || r.maxY < y) r.maxY = y;
  };
  const { minX, maxX, minY, maxY } = elements.reduce((r, element) => {
    switch (element.type) {
      case "area":
        if (["circle", "rectangle"].indexOf(element.areaType) < 0) {
          element.points.forEach((p) => updateFromPoint(r, p.x, p.y));
        } else {
          for (let { x, y } of rectangleCorners(element)) {
            updateFromPoint(r, x, y);
          }
        }
        break;
      case "sector":
      case "irrigationValveCable":
      case "tubing":
        element.points.forEach((p) => updateFromPoint(r, p.x, p.y));
        break;
      case "sprinkler":
      case "system-element":
      case "pipeline-point":
      case "rzws":
      case "raised-bed":
        updateFromPoint(r, element.x, element.y);
        break;
      case "pipeline":
        element.points.forEach((p) => updateFromPoint(r, p.x, p.y));
        break;
      case "background":
        if (uncutBackground) {
          updateFromPoint(r, 0, 0);
          updateFromPoint(r, element.width, element.height);
        }
        break;
      default:
        break;
    }
    return r;
  }, {});

  let paddingValues = {
    top: padding,
    right: padding,
    bottom: padding,
    left: padding
  };

  if (gridSize > 0) {
    padding = {
      top: (Math.abs(minY) % gridSize) + 1,
      right: gridSize - (Math.abs(maxX) % gridSize) + 1,
      bottom: gridSize - (Math.abs(maxY) % gridSize) + 1,
      left: (Math.abs(minX) % gridSize) + 1
    };

    for(const key in paddingValues) {
      if(paddingValues[key] > padding) {
        paddingValues[key] += gridSize * Math.ceil((padding - paddingValues[key]) / gridSize);
      }
    }
  }

  return {
    offsetX: Math.floor(minX) - paddingValues.left,
    offsetY: Math.floor(minY) - paddingValues.top,
    width:
      Math.ceil(maxX - minX) + paddingValues.left + paddingValues.right + 2,
    height:
      Math.ceil(maxY - minY) + paddingValues.top + paddingValues.bottom + 2,
  };
};

module.exports = {
  pixelSizeByZoom,
  generateGrid,
  sizeInPixelByMeters,
  sizeInMetersByPixel,
  getSizeByZoom,
  getZoomByScale,
  getSvgPoint,
  convertPlanJSON,
  offsetPlan,
  svgTextCondensed,
  scalePlan,
  convertPlanElements,
  getOffsetsAndSizeByElements,
};
