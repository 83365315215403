const { CubicBezier2D, QuadraticBezier2D } = require("kld-contours");
const { IntersectionQuery, Point2D, ShapeInfo } = require("kld-intersections");

const Intersect = require("path-intersection");

const { round } = require("./formatter");
const { mod } = require("./helpers");

/**
 * Converts from degree to radians.
 *
 * @param {*} degree
 */
const radiansFromDegree = (degree) => {
  return (degree * Math.PI) / 180;
};

/**
 * Calculates length of the bezier curve.
 *
 * @param {*} p0 start point {x, y}
 * @param {*} p1 end point
 * @param {*} bp bezier point - optional
 */
const lineLength = (p0, p1, bp) => {
  const result =
    bp != null && !bp.isDefault
      ? quadraticBezierLength(p0, bp, p1)
      : Math.sqrt((p0.x - p1.x) ** 2 + (p0.y - p1.y) ** 2);
  return result;
};

/**
 * Calculates length of the quadratic bezier curve
 *
 * @param {*} p0 start point {x, y}
 * @param {*} p1 bezier point
 * @param {*} p2 end point
 */
const quadraticBezierLength = (p0, p1, p2) => {
  const ax = p0.x - 2 * p1.x + p2.x;
  const ay = p0.y - 2 * p1.y + p2.y;
  const bx = 2 * p1.x - 2 * p0.x;
  const by = 2 * p1.y - 2 * p0.y;
  const A = 4 * (ax * ax + ay * ay);
  const B = 4 * (ax * bx + ay * by);
  const C = bx * bx + by * by;

  const Sabc = 2 * Math.sqrt(A + B + C);
  const A_2 = Math.sqrt(A);
  const A_32 = 2 * A * A_2;
  const C_2 = 2 * Math.sqrt(C);
  const BA = B / A_2;

  return (
    (A_32 * Sabc +
      A_2 * B * (Sabc - C_2) +
      (4 * C * A - B * B) * Math.log((2 * A_2 + BA + Sabc) / (BA + C_2))) /
    (4 * A_32)
  );
};

/**
 * Calculates direction for an array of points.
 *
 * @param {*} points
 * result > 0 -  clockwise
 * result < 0 -  counter-clockwise
 *
 */
const calculatePointsDirection = (points) => {
  if (points && points.length > 0) {
    const directionSum = points.reduce(
      (acc, obj) =>
        acc == null
          ? { x: obj.x, y: obj.y, sum: 0 }
          : {
              x: obj.x,
              y: obj.y,
              sum: acc.sum + (obj.x - acc.x) * (obj.y + acc.y),
            },
      null
    );

    const result = directionSum
      ? directionSum.sum +
        (points[0].x - directionSum.x) * (points[0].y + directionSum.y)
      : null;

    return result;
  }
  return null;
};

/**
 * Calculates angle by 3 points.
 *
 * @param {*} P1
 * @param {*} P2
 * @param {*} P3
 */
const calculateAngleByPoints = (P1, P2, P3) => {
  let result = undefined;
  if (P1 && P2 && P3) {
    var AB = Math.sqrt(Math.pow(P2.x - P1.x, 2) + Math.pow(P2.y - P1.y, 2));
    var BC = Math.sqrt(Math.pow(P2.x - P3.x, 2) + Math.pow(P2.y - P3.y, 2));
    var AC = Math.sqrt(Math.pow(P3.x - P1.x, 2) + Math.pow(P3.y - P1.y, 2));
    var cosVal = (BC * BC + AB * AB - AC * AC) / (2 * BC * AB);
    if (cosVal > 1) cosVal = 1;
    if (cosVal < -1) cosVal = -1;
    result = (Math.acos(cosVal) * 180) / Math.PI;
  }
  return result;
};

/**
 * Calculates angle of the vector in svg coordinates system.
 *
 * @param {*} p1 - line start point
 * @param {*} p2 - line end point
 */
const calculateAngleByLine = (p1, p2) => {
  var dy = p2.y - p1.y;
  var dx = p2.x - p1.x;
  var theta = Math.atan2(dy, dx); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  return theta;
};

/**
 * Rotate a point over an angle clockwise in svg coordinates system
 * with respect to the center point.
 *
 * @param {*} point
 * @param {*} center
 * @param {*} angle
 */
const rotatePoint = (point, center, angle) => {
  const s = Math.sin((angle * Math.PI) / 180);
  const c = Math.cos((angle * Math.PI) / 180);

  const result = {
    x: c * (point.x - center.x) + s * (point.y - center.y) + center.x,
    y: c * (point.y - center.y) - s * (point.x - center.x) + center.y,
  };
  return result;
};

/**
 * Rotates a vector clockwise in the left-handed (canvas/svg) coordinate system.
 *
 * @param {*} x
 * @param {*} y
 * @param {*} a
 * @param {*} digits
 */
const rotateVector = (x, y, a, digits = 0) => {
  const r = [
    x * Math.cos(a) - y * Math.sin(a),
    x * Math.sin(a) + y * Math.cos(a),
  ];

  return digits > 0 ? r.map((e) => round(e, digits)) : r;
};

/**
 * Gauss Square Formula
 *
 * @param {*} points
 */
const polygonSquare = (points) => {
  let size = 0;
  const len = points.length;
  for (let i = 0; i < len; i++) {
    size += points[i].x * points[(i + 1) % len].y;
    size -= points[(i + 1) % len].x * points[i].y;
  }

  return Math.abs(size) / 2;
};

/**
 * Generates a kld bezier polygon from path.
 *
 * @param {*} path
 */
const generateShapePoints = (path) => {
  let result = [];
  const shape = ShapeInfo.path(path);
  if (shape && shape.args && shape.args.length > 0) {
    for (let i = 0; i < shape.args.length; i++) {
      const { name, args } = shape.args[i];
      let b1 = null;
      const filteredArgs =
        args != null && args.length > 0
          ? args
              .filter((a) => a !== undefined)
              .map((a) => new Point2D(a.x, a.y))
          : [];
      let points = [];
      switch (name) {
        case "Bezier2":
          b1 = new QuadraticBezier2D(...filteredArgs);
          points = b1.toPolygon2D().points;
          break;
        case "Bezier3":
          b1 = new CubicBezier2D(...filteredArgs);
          points = b1.toPolygon2D().points;
          break;
        case "Line":
          points = filteredArgs;
          break;
        default:
          break;
      }
      if (points) {
        if (
          result.length > 0 &&
          points[0].x === result[result.length - 1].x &&
          points[0].y === result[result.length - 1].y
        ) {
          points.splice(0, 1);
        }
        result.push(...points);
      }
    }
  }
  return result;
};

/**
 * Performs a check if two points are close enough
 * to be considered equal
 *
 * @param {*} a
 * @param {*} b
 */
const pointsEqualWithThreshold = (a, b, threshold = 2) =>
  Math.abs(a.x - b.x) <= threshold && Math.abs(a.y - b.y) <= threshold;

/**
 * Locates intersections of two paths.
 *
 * @param {*} a - path
 * @param {*} b - path
 */
const intersect = (aPath, bPath) =>
{}

/**
 * Helper function to remove unnecessary props from a point-like object.
 * @param {*} param0
 */
const pointFactory = ({ x, y }) => ({ x, y });

/**
 * Locates center between 2 points.
 *
 * @param {*} a
 * @param {*} b
 */
const centerPoint = (a, b) => ({ x: (a.x + b.x) / 2, y: (a.y + b.y) / 2 });

/**
 * Sorter to sort points by x ascending.
 *
 * @param {*} a
 * @param {*} b
 */
const xSorter = (a, b) => a.x - b.x;
/**
 * Sorter to sort points by x ascending.
 *
 * @param {*} a
 * @param {*} b
 */
const ySorter = (a, b) => a.y - b.y;

/**
 * Generates a line function by 2 points, that can be used to find x or y.
 * Can return NaN.
 *
 * WARNING: DO NOT USE WHEN a.x === b.x OR a.y === b.y
 *
 * @param {*} a - point
 * @param {*} b - point
 */
const lineFnFactory = (a, b) => (x, y) => {
  return y == null
    ? ((a.y - b.y) * x + (a.x * b.y - b.x * a.y)) / (a.x - b.x)
    : ((b.x - a.x) * y + (a.x * b.y - b.x * a.y)) / (b.y - a.y);
};

/**
 * Checks if a value lies inside a range within a threshold.
 * The order of range is omited.
 *
 * @param {*} v - value
 * @param {*} a
 * @param {*} b
 * @param {*} threshold
 */
const inRange = (v, a, b, threshold = 0) => {
  [a, b] = [a, b].sort((a, b) => a - b);
  return a - threshold <= v && v <= b + threshold;
};

/**
 * Checks if a point lies on a line segment
 * formed by firstPoint and secondPoint.
 *
 * @param {*} point
 * @param {*} firstPoint
 * @param {*} secondPoint
 */
const pointInSegment = (
  point,
  firstPoint,
  secondPoint,
  threshold = 0.01,
  lineFnThreshold = 0.01
) => {
  const [a, b] = [firstPoint, secondPoint].sort(xSorter);
  if ([a, b].some((p) => pointsEqualWithThreshold(p, point, threshold))) {
    return true;
  }
  const lineFn = lineFnFactory(a, b);
  const linePoint =
    Math.abs(a.x - b.x) < Math.abs(a.y - b.y)
      ? { x: lineFn(null, point.y), y: point.y }
      : { x: point.x, y: lineFn(point.x) };

  if (!inRange(linePoint.x, a.x, b.x, lineFnThreshold)) {
    return false;
  }

  if (!inRange(linePoint.y, a.y, b.y, lineFnThreshold)) {
    return false;
  }

  return pointsEqualWithThreshold(point, linePoint, threshold);
};

const pointInLine = (point, lineStart, lineEnd, threshold = 0) => {
  const closestPoint = closestPointInLine(point, lineStart, lineEnd, 0);

  return closestPoint ? lineLength(point, closestPoint) <= threshold : false;
};

/**
 * Generates an array of lines formed by a linked array of points.
 *
 * @param {*} points - linked array of points.
 * @param {*} enclosed - points form an enclosed figure.
 * @param {*} withControlPoints - array of points contains control points.
 */
const linesByPoints = (points, enclosed = false, withControlPoints = false) => {
  const lines = [];
  if (points && points.length > 0) {
    const allPoints = points.slice();
    if (enclosed) {
      allPoints.push(points[0]);
    }
    let k = withControlPoints ? 2 : 1;
    for (let i = 0, l = allPoints.length; i < l - k; i = i + k) {
      const a = allPoints[i];
      const b = allPoints[i + k];
      const c = allPoints[i + 1];

      lines.push({
        start: a,
        end: b,
        controlIsDefault: k > 1 ? c.isDefault : true,
        control: k > 1 ? c : centerPoint(a, b),
        length: k > 1 ? c.length : lineLength(a, b),
      });
    }
  }
  return lines;
};

/**
 * Checks if a point lies in path.
 *
 * WARNING: Magic function.
 *
 * @param {*} point {x, y}
 * @param {*} path - svg path
 */
const pointInPath = (point, path) => {
  const { x, y } = point;
  const shapePoints = generateShapePoints(path);
  return IntersectionQuery.pointInPolygon(new Point2D(x, y), shapePoints);
};

/**
 * Locates closest point on a line.
 *
 * @param {*} point
 * @param {*} start - line start
 * @param {*} end - line end
 * @param {*} padding - padding from line start or line end (optional)
 */
const closestPointInLine = (point, start, end, padding = 0) => {
  const l = {
    x: end.x - start.x,
    y: end.y - start.y,
  };
  if (l.x === 0 && l.y === 0) return start;

  const d = {
    x: start.x - point.x,
    y: start.y - point.y,
  };

  const t = -(d.x * l.x + d.y * l.y) / (l.x ** 2 + l.y ** 2);

  const dist = Math.sqrt(l.x ** 2 + l.y ** 2);
  const k = dist !== 0 && dist > padding ? padding / dist : 0;

  const nearStart =
    padding > 0 ? { x: start.x + k * l.x, y: start.y + k * l.y } : start;
  const nearEnd =
    padding > 0
      ? { x: start.x + (1 - k) * l.x, y: start.y + (1 - k) * l.y }
      : end;

  return t <= 0
    ? nearStart
    : t >= 1
    ? nearEnd
    : { x: start.x + t * l.x, y: start.y + t * l.y };
};

/**
 * Intersection point of two lines
 *
 * p1, p2 -- the coefficients ({k, b}) of the equation y = k*x+b
 * if the line is parallel to the axis Y, then ({x})
 * @param {*} p1 line
 * @param {*} p2 line
 *
 * @returns {x, y} Intersection point of two lines
 */
const getIntersect2Lines = (p1, p2) => {
  let x;
  let y;

  // if the p1 is parallel to the axis Y
  if (p1.x != null && p2.x == null) {
    x = p1.x;
    y = p2.k * x + p2.b;
  }
  // if the p2 is parallel to the axis Y
  else if (p1.x == null && p2.x != null) {
    x = p2.x;
    y = p1.k * p2.x + p1.b;
  }
  // if p1 and p2 have the form y=k*x+b
  else if (p1.x == null && p2.x == null) {

    // Lines are parallel and will never intersect
    if (p1.k === p2.k) 
    return undefined;

    x = (p2.b - p1.b) / (p1.k - p2.k);
    y = p1.k * x + p1.b;
  }

  if (x == null && y == null) return undefined;

  return {
    x: x,
    y: y,
  };
};

/**
 * Returns an array of intersections of two lines
 * or returns undefined.
 *
 * @param {*} p0
 * @param {*} p1
 * @param {*} p2
 * @param {*} p3
 */
const linesIntersection = (p0, p1, p2, p3, segmentIntersection = true) => {
  const s1_x = p1.x - p0.x;
  const s1_y = p1.y - p0.y;
  const s2_x = p3.x - p2.x;
  const s2_y = p3.y - p2.y;

  const divider = -s2_x * s1_y + s1_x * s2_y;

  // parallel
  if (divider === 0) {
    let points = [];
    const l1 = [p0, p1];
    const l2 = [p2, p3];

    [l1, l2].forEach((l, i, arr) => {
      l.forEach((p) => {
        if (pointInSegment(p, ...arr[mod(i + 1, arr.length)])) {
          points.push(p);
        }
      });
    });

    if (points.length === 0) return undefined;
    points = points.filter(
      (c, i, arr) =>
        arr.findIndex((p) => pointsEqualWithThreshold(p, c, 0)) === i
    );
    return points.map(pointFactory);
  }

  // find value of paramteric representation of the lines
  const s = (-s1_y * (p0.x - p2.x) + s1_x * (p0.y - p2.y)) / divider;
  const t = (s2_x * (p0.y - p2.y) - s2_y * (p0.x - p2.x)) / divider;

  // Collision detected
  if (!segmentIntersection || (s >= 0 && s <= 1 && t >= 0 && t <= 1)) {
    return [{ x: s1_x * t + p0.x, y: s1_y * t + p0.y, t, s }];
  }

  return undefined;
};

/**
 * Convert point-to-point to polygon
 * @param {*} points point to point array including bezier anchor points
 * points = [
 *  {
 *    x, -- coord X
 *    y, -- coord Y
 *    type -- "control-point" then bezier anchor points,
 *    isDefault --- false then bezier linear curve
 *   }
 * ]
 * @param {*} n > 0; represents each bezier curve in the form of (n + 1) segments
 * @returns array polygon vertices
 */
const convertBezierToPoligon = (points, n) => {
  let resPoint = [];
  let t = 1 / n;

  const l = points.length;

  points.forEach(({ isDefault, type }, i) => {
    // if it is a quadratic bezier curve
    if (!isDefault && type === "control-point") {
      /**
       * p0 - beginning of curve
       * p1 - bezier anchor point
       * p2 - end of curve
       */
      let p0 = points[i - 1] == null ? points[l - 1] : points[i - 1];
      let p1 = points[i];
      let p2 = i + 1 === l || points[i + 1] == null ? points[0] : points[i + 1];

      const l0 = lineLength(p0, p2);
      const l1 = lineLength(p0, p2, p1);

      // we get the necessary points of the bezier curve
      let tPoints = [];
      if (l0 !== l1) {
        for (let j = 0; j <= 1; j = j + t) {
          /**
           * x0,y0 - coordinate of the beginning of the vector describing the point p1
           * x1,y1 - coordinate of the end of the vector describing the point p1
           */
          let x0 = (1 - j) * p0.x + j * p1.x;
          let y0 = (1 - j) * p0.y + j * p1.y;
          let x1 = (1 - j) * p1.x + j * p2.x;
          let y1 = (1 - j) * p1.y + j * p2.y;

          /**
           * describe the straight line {x0, y0};{x1, y1}
           */
          let k = (y1 - y0) / (x1 - x0);
          tPoints.push(
            isFinite(k)
              ? {
                  k: k,
                  b: -k * x0 + y0,
                }
              : {
                  x: x1,
                }
          );
        }
      }

      for (let j = 0; j <= tPoints.length - 2; j++) {
        /**
         * get the intersection points of the lines
         */
        const p = getIntersect2Lines(tPoints[j], tPoints[j + 1]);
        if (p) {
          resPoint.push(p);
        }
      }
    }
    // if it is a straight line
    else if (type !== "control-point") {
      resPoint.push({ x: points[i].x, y: points[i].y });
    }
  });

  return resPoint;
};

/**
 * Sinus that understands degrees
 * @param {*} alpha
 */
const sin = (alpha) => {
  return Math.sin((alpha / 180) * Math.PI);
};

/**
 * Cosinus that understands degrees
 * @param {*} alpha
 */
const cos = (alpha) => {
  return Math.cos((alpha / 180) * Math.PI);
};

/**
 * Checks if a point lies in a polygon defined by points.
 *
 * ray-casting algorithm
 *
 * @param {*} a
 * @param {*} points
 */
const pointInPolygon = (a, points) => {
  const { x, y } = a;

  let inside = false;
  for (var i = 0, j = points.length - 1; i < points.length; j = i++) {
    const xi = points[i].x;
    const yi = points[i].y;
    const xj = points[j].x;
    const yj = points[j].y;

    const val =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (val) inside = !inside;
  }

  return inside;
};

const cutLineFromSegments = (segments, line, threshold) => {
  let result = [];
  if (segments && segments.length > 0) {
    segments.forEach((segment) => {
      let segmentStartInLine = pointInLine(
        segment.start,
        line.start,
        line.end,
        threshold
      ); // start segmentStart end

      let segmentEndInLine = pointInLine(
          segment.end,
          line.start,
          line.end,
          threshold
        ), // start segmentEnd end
        lineStartInSegment = pointInLine(
          line.start,
          segment.start,
          segment.end,
          threshold
        ), // segmentStart start segmentEnd
        lineEndInSegment = pointInLine(
          line.end,
          segment.start,
          segment.end,
          threshold
        ); // segmentStart end segmentEnd
      let pointsCount = [
        segmentStartInLine,
        segmentEndInLine,
        lineStartInSegment,
        lineEndInSegment,
      ].reduce((acc, c) => {
        return c ? acc + 1 : acc;
      }, 0);
      if (pointsCount > 1) {
        if (segmentStartInLine && segmentEndInLine) {
          //(C3) segment in line
          // nothing push to result
        } else if (lineStartInSegment && lineEndInSegment) {
          //(C4) line in segment
          const isToStart =
            lineLength(segment.start, line.start) <
            lineLength(segment.start, line.end);
          result.push(
            ...[
              {
                start: segment.start,
                end: isToStart ? line.start : line.end,
              },
              {
                start: isToStart ? line.end : line.start,
                end: segment.end,
              },
            ]
          );
        } else {
          if (segmentStartInLine) {
            //(C1) segmentStartInLine and line start or end in segment
            result.push({
              start: lineEndInSegment ? line.end : line.start,
              end: segment.end,
            });
          } else {
            //(C2) segmentEndInLine and line start or end in segment
            result.push({
              start: segment.start,
              end: lineEndInSegment ? line.end : line.start,
            });
          }
        }
      } else result.push(segment);
    });
  }
  return result.filter((s) => s.start.x !== s.end.x || s.start.y !== s.end.y);
};

const polygonizeCircle = (area, max, n) => {
  // we add 1 as threshold to avoid rasterization problems
  const ar = area.width / 2 + 1;
  const br = area.height / 2 + 1;

  if (n == null) {
    const perimiter = 2 * Math.PI * Math.sqrt((ar ** 2 + br ** 2) / 2);
    n = Math.max(Math.ceil(perimiter / max), 5);

    if (n % 2 > 0 && Math.min(ar, br) / Math.max(ar, br) < 0.9) {
      n += 1;
    }
  }

  let points = [];
  const additionalAng = area.width < br ? 90 : 0;
  for (let i = 0; i < n; i++) {
    let ang = additionalAng + (i * 360) / n;
    const vector = rotateVector(
      ar * cos(ang),
      br * sin(ang),
      radiansFromDegree(area.startAngle)
    );
    const x = area.x + vector[0];
    const y = area.y + vector[1];

    points.push({ x, y });
  }

  return points;
};

/**
 * Checks if given polyline is arranged clockwise. If polyline is CCW, it converts it into CW.
 *
 * @param {*} points
 */
const convertPointsToDirection = (points, isCW = true) => {
  if (calculatePointsDirection(points) * (isCW ? 1 : -1) > 0) {
    points.reverse();
  }
  // ensure initial point is not a control-point
  if (points[0].type === "control-point") {
    points.push(points.shift());
  }
};

module.exports = {
  lineLength,
  calculatePointsDirection,
  calculateAngleByPoints,
  calculateAngleByLine,
  rotatePoint,
  rotateVector,
  polygonSquare,
  generateShapePoints,
  pointsEqualWithThreshold,
  intersect,
  pointFactory,
  centerPoint,
  xSorter,
  ySorter,
  lineFnFactory,
  pointInSegment,
  linesByPoints,
  pointInPath,
  closestPointInLine,
  getIntersect2Lines,
  convertBezierToPoligon,
  radiansFromDegree,
  sin,
  cos,
  pointInPolygon,
  pointInLine,
  cutLineFromSegments,
  linesIntersection,
  polygonizeCircle,
  inRange,
  convertPointsToDirection,
};
