import React from "react";

import NumberInput from "../inputs/NumberInput";

import { ReactComponent as PlusSVG } from "./../../assets/plus-small.svg";
import { ReactComponent as MinusSVG } from "./../../assets/minus-small.svg";

function QuantityInput({
  quantity,
  defaultQuantity,
  changeQuantity,
  disabled,
  className,
}) {
  if (isNaN(quantity)) quantity = 0;
  if (isNaN(defaultQuantity)) defaultQuantity = 0;

  const [isInputActive, setIsInputActive] = React.useState(
    quantity !== defaultQuantity
  );

  const inputRef = React.useRef();
  const controlPlusRef = React.useRef();
  const controlMinusRef = React.useRef();

  React.useLayoutEffect(() => {
    const controlPlusNode = controlPlusRef.current;
    const controlMinusNode = controlMinusRef.current;

    const listener = (delta) => (e) => {
      e.preventDefault();
      if (!disabled) {
        const q = Math.max(+quantity + delta, 0);
        changeQuantity(q);
        setIsInputActive(q !== defaultQuantity);
      }
    };

    const controlPlusListener = listener(+1);
    const controlMinusListener = listener(-1);

    controlPlusNode.addEventListener("click", controlPlusListener);
    controlMinusNode.addEventListener("click", controlMinusListener);

    return () => {
      controlPlusNode.removeEventListener("click", controlPlusListener);
      controlMinusNode.removeEventListener("click", controlMinusListener);
    };
  }, [changeQuantity, defaultQuantity, disabled, quantity]);

  return (
    <div className={className}>
      <label className={`recom-input ${isInputActive ? "active" : ""}`}>
        <span>
          <NumberInput
            ref={inputRef}
            type="text"
            value={quantity}
            onChange={changeQuantity}
            isInteger={true}
            disabled={disabled}
            onFocus={() => setIsInputActive(true)}
            onBlur={() => setIsInputActive(quantity !== defaultQuantity)}
          />
          {isInputActive ? `/${defaultQuantity}` : ""}
        </span>
      </label>

      <div className={`setting-control ${disabled ? "disabled" : ""}`}>
        <div ref={controlPlusRef} className="setting-control-item">
          <PlusSVG />
        </div>
        <div ref={controlMinusRef} className="setting-control-item">
          <MinusSVG />
        </div>
      </div>
    </div>
  );
}

export default QuantityInput;
