import React from "react";
import { useAutoFocus } from "../../hooks/useAutoFocus";
import { useOuterClickEffect } from "../../hooks/useOuterClickEffect";

import ToolButton from "../elements/ToolButton";

import Link from "../elements/Link";




import { ReactComponent as LogoSVG } from "../../assets/rainshift_logo.svg";
// import { ReactComponent as LogoSVG } from "../../assets/logo.svg";
import { ReactComponent as LogoRundSVG } from "../../assets/logo-rund.svg";
import { ReactComponent as ConfirmSVG } from "../../assets/confirm.svg";
import { ReactComponent as EditSVG } from "../../assets/edit.svg";

function Logo({ planName, setPlanName, updatePlan = () => {} }) {
  const [hasEdit, changeEdit] = React.useState(false);
  const ref = React.useRef();

  const prevPlanNameRef = React.useRef(planName);

  const submit = (e) => {
    e?.preventDefault();
    changeEdit(false);

    if (planName !== prevPlanNameRef.current) {
      updatePlan();
      prevPlanNameRef.current = planName;
    }
  };

  useAutoFocus(ref, [hasEdit]);
  useOuterClickEffect(ref, submit);

  return (
    <div className="logo">

      <div className="logo-content">
        <Link href="/">
          <i>
            <LogoSVG />
          </i>
        </Link>

        <div
          className={`plan-name ${hasEdit ? "edit" : ""}`}
          onClick={(e) => {
            e.preventDefault();

            if (typeof setPlanName === "function" && !hasEdit) {
              changeEdit(true);
            }
          }}
        >
          {typeof setPlanName === "function" && (
            <>
              {hasEdit ? (
                <>
                  <input
                    ref={ref}
                    type="text"
                    defaultValue={planName}
                    onChange={(e) => {
                      setPlanName(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        submit(e);
                      }
                    }}
                  />
                  <ToolButton
                    onClick={() => {
                      submit();
                    }}
                  >
                    <ConfirmSVG />
                  </ToolButton>
                </>
              ) : (
                <>
                  <span>{planName}</span>
                  <ToolButton>
                    <EditSVG />
                  </ToolButton>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Logo;
