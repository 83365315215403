import "./styles/scss/app.scss";

import { createRoot } from "react-dom/client";
import { Provider } from "mobx-react";
import { RawIntlProvider } from "react-intl";

import Helpers from "@dvsproj/ipat-core/helpers";
import { intlStore } from "@dvsproj/ipat-core/intlStore";

import * as serviceWorker from "./serviceWorker";

import uiStateFactory from "./store/uiState";
import { calcApi } from "./utils/api";

import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorPopup from "./components/popups/ErrorPopup";
import { cookies } from "./utils/uiUtils";
import initGoogleTagManager from "./googleTagManager";
import initSib from "./sib";

// This is optional but highly recommended
// since it prevents memory leak

// CONFIGURE HELPERS
Helpers.performance = performance;

window.runApplication = async () => {
  let Component = null;
  try {
    const settings = await calcApi.fetchSettingsFile();
    const intl = intlStore(
      cookies?.language
        ? cookies?.language.replace("_", "-")
        : window?.navigator?.language
    );
    const uiState = uiStateFactory(settings, intl);

    Component = (
      <ErrorBoundary>
        <Provider uiState={uiState}>
          <RawIntlProvider value={intl}>
            <App />
          </RawIntlProvider>
        </Provider>
      </ErrorBoundary>
    );
  } catch (e) {
    console.error(e);
    Component = (
      <div id="dvs-plan">
        <ErrorPopup message="Failed to load settings" className="error-popup" />
      </div>
    );
  }

  const root = createRoot(document.getElementById("root"));

  root.render(Component);

  initGoogleTagManager();
  initSib();

  serviceWorker.unregister();
};

window.runApplication();