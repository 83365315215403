import { GR_ROTATOR_ID } from "@dvsproj/ipat-core/settings/settingsUtils"

function sprinklerKits(settings, plan) {
  const { bomList, elements } = settings
  const { flags, mpRotatorTool, gdRotatorTool } = elements

  let bomItems = []
  if (plan.sprinklers.length === 0) return bomItems

  for (const sprinkler of plan.sprinklers) {
    const config = sprinkler.findConfig()
    if (config == null) throw new Error(`Sprinkler config not found`)

    if (config.bomId != null) {
      bomItems.push({
        bomId: config.bomId,
        qualityList: bomList[config.bomId],
        quantity: 1
      })
    }

    const nozzleTypeElement = elements[config.nozzleType]
    if (nozzleTypeElement == null) {
      throw new Error(`Elements for ${config.nozzleType} not found`)
    }

    const nozzleTypeByTube = nozzleTypeElement.find(
        e => e.type === plan.irrigationTubeType
    )
    if (nozzleTypeByTube == null) {
      throw new Error(`Nozzle type for ${plan.irrigationTubeType} not found`)
    }

    bomItems.push({
      bomId: nozzleTypeByTube.bomId,
      qualityList: bomList[nozzleTypeByTube.bomId],
      quantity: 1
    })

    bomItems.push({
      bomId: flags.bomId,
      qualityList: bomList[flags.bomId],
      quantity: 1
    })
  }

  const rotatorTool =
      plan.sprinklerSetType === GR_ROTATOR_ID ? gdRotatorTool : mpRotatorTool

  bomItems.push({
    bomId: rotatorTool.bomId,
    qualityList: bomList[rotatorTool.bomId],
    quantity: 1
  })

  return bomItems
}

export { sprinklerKits }
