import { useIntl } from "react-intl";
import ToolButton from "../elements/ToolButton";

import { priceFormat } from "@dvsproj/ipat-core/formatter";

import { ReactComponent as BuySVG } from "./../../assets/buy.svg";
import { ReactComponent as DvsCheckSVG } from "./../../assets/dvs-check.svg";
import { ReactComponent as PdfDocumentSVG } from "./../../assets/install.svg";
import { ReactComponent as InstallationTimeSVG } from "./../../assets/installation-time.svg";
import { ReactComponent as InstallerSVG } from "./../../assets/installer.svg";
import { ReactComponent as ProjectCalculationSVG } from "./../../assets/project-calculation.svg";
import { ReactComponent as RainBirdSVG } from "./../../assets/rain-bird.svg";
import { ReactComponent as WishListSVG } from "./../../assets/wish-list.svg";

const FooterButton = ({
  icon,
  title,
  onClick,
  position = "left",
  disabled = false,
  className = "",
  ...props
}) => {
  const Component =
    typeof onClick === "function"
      ? (props) => (
          <ToolButton disabled={disabled} onClick={onClick} {...props} />
        )
      : (props) => <div {...props} />;

  return (
    <Component
      className={["button-item", position, className]
        .filter((e) => e)
        .join(" ")}
      {...props}
    >
      <i>{icon}</i>
      <span>{title}</span>
    </Component>
  );
};

function BomFooter({
  user,
  planIsEditable,
  projectCalculation,
  requestRainBirdProducts,
  requestInstallation,
  generatePdf,
  dvsCheckButtonLabel,
  planToCheck,
  redirectWishList,
  planToFinalize,
  totalPrice,
  minInstallationTime,
  maxInstallationTime,
  buyIsDisabled,
}) {
  const intl = useIntl();
  const { formatMessage, formatNumber } = intl;

  return (
    <>
      <div className="left-controls">
        <FooterButton
          icon={<RainBirdSVG width={32} height={32} />}
          title={formatMessage({ id: "texts.steps.bom.popup.rainBird" })}
          onClick={requestRainBirdProducts}
        />

        <FooterButton
          icon={<PdfDocumentSVG width={32} height={32} />}
          title={formatMessage({ id: "texts.steps.bom.popup.downloadPDF" })}
          onClick={generatePdf}
        />

        <FooterButton
          icon={<DvsCheckSVG width={32} height={32} />}
          disabled={planToCheck == null}
          title={dvsCheckButtonLabel}
          onClick={planToCheck}
        />

        <div className="separator" />

        <div />

        <FooterButton
          icon={<InstallerSVG width={32} height={32} />}
          title={formatMessage({ id: "texts.steps.bom.popup.installer" })}
          onClick={requestInstallation}
        />

        <FooterButton
          icon={<WishListSVG width={32} height={32} />}
          disabled={!planIsEditable}
          title={formatMessage({ id: "texts.steps.bom.popup.wishList" })}
          onClick={redirectWishList}
        />

        {user.role === "partner" && (
          <>
            <div className="separator" />
            <div />
            <FooterButton
              icon={<InstallationTimeSVG width={32} height={32} />}
              title={formatMessage(
                { id: "texts.steps.bom.popup.installationTime" },
                {
                  min: formatNumber(Math.ceil(minInstallationTime / 60)),
                  max: formatNumber(Math.ceil(maxInstallationTime / 60)),
                }
              )}
            />
            <FooterButton
              icon={<ProjectCalculationSVG width={32} height={32} />}
              title={formatMessage({
                id: "texts.steps.bom.popup.projectCalculation",
              })}
              onClick={projectCalculation}
            />
          </>
        )}
      </div>

      <div className="right-controls">
        <div className="price-total">
          {formatMessage({ id: "texts.steps.bom.popup.totalPrice" })}
          &nbsp;&nbsp;
          <span className="price">{priceFormat(intl, totalPrice)}</span>
        </div>

        <div className="buy-wrap">
          <FooterButton
            className="buy"
            icon={<BuySVG width={40} height={40} />}
            disabled={!planIsEditable || buyIsDisabled}
            title={formatMessage({ id: "texts.steps.bom.popup.finalButton" })}
            onClick={planToFinalize}
            position="right"
          />
        </div>
      </div>
    </>
  );
}

export default BomFooter;
