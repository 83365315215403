import React, { useMemo, forwardRef, useImperativeHandle, useRef } from "react";
import { useIntl } from "react-intl";

const NumberInput = forwardRef(
  (
    {
      value,
      onChange,
      separator = ".",
      type,
      numberValidate,
      saveHandle,
      ...props
    },
    ref
  ) => {
    const number = useMemo(
      () => (value != null ? (value + "").replace(".", separator) : ""),
      [separator, value]
    );

    const input = useRef();

    useImperativeHandle(ref, () => ({
      value: input.current.value,
      focus: () => input.current.focus(),
      blur: () => input.current.focus(),
    }));

    const [inValue, setValue] = React.useState(number);

    let tempVal = +inValue.replace(separator, ".");

    if (!isNaN(tempVal) && tempVal !== +value) {
      setValue(number);
    }

    return (
      <React.Fragment>
        <input
          type="text"
          value={inValue}
          onChange={({ target }) => {
            let tValue = numberValidate(target.value);
            if (tValue !== +value) {
              saveHandle(tValue);
            }
            setValue(tValue);
          }}
          ref={input}
          {...props}
        />
      </React.Fragment>
    );
  }
);

let NumberInputWithIntl = (
  {
    value,
    isInteger,
    isNegative,
    precision = 1,
    onChange = () => {},
    max,
    ...props
  },
  ref
) => {
  const { formatNumberToParts } = useIntl();
  const parts = formatNumberToParts(9999.99);
  const separator = parts?.find((i) => i?.type === "decimal")?.value || ".";

  const numberValidate = (
    value,
    separator,
    precision,
    isNegative,
    isInteger,
    max
  ) => {
    let tValue = value != null ? (value + "").replace(".", separator) : "";

    const separatorPattern = separator === "." ? "\\." : separator;

    if (isInteger) {
      let rPattern = new RegExp(`[^\\d]+`);
      let nPattern = new RegExp(`[^-\\d]+`);

      tValue = tValue.replace(isNegative ? nPattern : rPattern, "");

      let pattern = isNegative ? new RegExp("-?\\d+") : new RegExp("\\d+");

      if (tValue.match(pattern) != null) {
        tValue = tValue.match(pattern)[0];
      }
    } else {
      let rPattern = new RegExp(`[^\\d${separatorPattern}]+`);
      let nPattern = new RegExp(`[^-\\d${separatorPattern}]+`);

      tValue = tValue.replace(isNegative ? nPattern : rPattern, "");

      // precision
      let pattern = isNegative
        ? new RegExp("-?\\d+" + separatorPattern + "?\\d{0," + precision + "}")
        : new RegExp("\\d+" + separatorPattern + "?\\d{0," + precision + "}");

      if (tValue.match(pattern) != null) {
        tValue = tValue.match(pattern)[0];
      }
    }
    tValue = tValue.replace(/^0(\d)/, "$1");

    return tValue > max ? max + "" : tValue;
  };

  return (
    <NumberInput
      ref={ref}
      {...props}
      separator={separator}
      value={value}
      numberValidate={(value) =>
        numberValidate(value, separator, precision, isNegative, isInteger, max)
      }
      saveHandle={(value) => {
        if (value.substr(-1, 1) === separator) {
          value = value.substr(0, value.length - 1);
        }
        onChange(value.replace(separator, "."));
      }}
    />
  );
};
NumberInputWithIntl = forwardRef(NumberInputWithIntl);

export default NumberInputWithIntl;
