const { createEmptyMatrix, cloneMatrix } = require("./matrixUtils");
const clustering = require("density-clustering");
const {
  cos,
  sin,
  lineLength,
  rotatePoint,
  pointInPolygon,
  closestPointInLine,
} = require("./geometryUtils");
const { getStrightEdges } = require("./areaUtils");
/**
 * Generates a polygon (array of points) for a sprinkler sector.
 *
 * @param {*} s
 */
const polygonizeRotatorSprinkler = (
  { x, y, circleRadius: r, circleSectorAngle, startAngle },
  angStep = 1
) => {
  let ss = [];

  // add an edge from center for non 360 sprinklers
  if (circleSectorAngle !== 360) {
    ss.push({ x, y });
  }

  // iterate over the circle sector
  // and push coordinates into resulting array
  const stopAngle = startAngle - 90 + circleSectorAngle;
  for (let ang = startAngle - 90; ang <= stopAngle; ang += angStep) {
    let xx = x + r * cos(ang);
    let yy = y + r * sin(ang);

    ss.push({ x: xx, y: yy });
  }

  // add an edge to center for non 360 sprinklers
  if (circleSectorAngle !== 360) {
    ss.push({ x, y });
  }

  // remove duplicates
  return ss.filter(
    (s, i, arr) => i === 0 || s.x !== arr[i - 1].x || s.y !== arr[i - 1].y
  );
};

/**
 * Returns precipitation of a sprinkler by distance.
 *
 * @param {*} sprinkler with precipitation, maxRadius, circleRadius properties
 * @param {*} distanceFromCenter in 10cm units
 */
const sprinklerPrecipitationByDistance = (
  { precipitation, maxRadius, radius },
  distanceFromCenter
) => {
  // ratio of radius to max radius (<= 1)
  const coef = radius / (maxRadius * 10);

  const distanceInFeet = distanceFromCenter / (10 * 0.3048);
  const d = distanceInFeet / coef;
  let rangeIndecies = [Math.floor(d), Math.ceil(d)];
  if (
    rangeIndecies.some((index) => index < 0 || index >= precipitation.length)
  ) {
    return 0;
  }

  const [p1, p2] = rangeIndecies.map((i) => precipitation[i]);
  return (d - rangeIndecies[0]) * (p2 - p1) + p1;
};

/**
 * Returns strip sprinkler coverage mask with offsets and sizes.
 *
 * @param {*} sprinkler with  x, y, rectType, startAngle, rectWidth, rectHeight properties
 */
const stripSprinklerCoverage = ({
  x,
  y,
  rectType,
  startAngle,
  rectWidth,
  rectHeight,
}) => {
  const corners = stripSprinklerCorners({
    x,
    y,
    rectType,
    startAngle,
    rectWidth,
    rectHeight,
  });
  const minx = Math.floor(Math.min(...corners.map((x) => x.x)));
  const miny = Math.floor(Math.min(...corners.map((x) => x.y)));
  const maxx = Math.ceil(Math.max(...corners.map((x) => x.x)));
  const maxy = Math.ceil(Math.max(...corners.map((x) => x.y)));

  const width = maxx - minx;
  const height = maxy - miny;

  const matrix = createEmptyMatrix(width, height, 0);

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      if (pointInPolygon({ x: x + minx, y: y + miny }, corners)) {
        matrix[y][x] = 1;
      }
    }
  }
  return {
    offsetX: minx,
    offsetY: miny,
    matrix,
    width,
    height,
  };
};
/**
 * Returns sprinkler coverage mask with offsets and sizes.
 *
 * @param {*} sprinkler with x, y, circleRadius, startAngle, circleSectorAngle properties
 */
const rotatorSprinklerCoverage = ({
  x,
  y,
  circleRadius,
  startAngle,
  circleSectorAngle,
}) => {
  const offsetX = Math.floor(x - circleRadius);
  const offsetY = Math.floor(y - circleRadius);

  const width = Math.ceil(circleRadius * 2) + 1;
  const height = width;

  const matrix = createEmptyMatrix(width, height, 0);

  const stopAngle = startAngle + circleSectorAngle - 90;
  for (let r = 0; r <= circleRadius; r += 1) {
    for (let ang = startAngle - 90; ang <= stopAngle; ang += 0.1) {
      let x = Math.round(circleRadius + r * cos(ang));
      let y = Math.round(circleRadius + r * sin(ang));

      if (x >= 0 && y >= 0 && x < width && y < height) {
        matrix[y][x] |= 1024;
      }
    }
  }

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      if (matrix[y][x] > 1023) {
        matrix[y][x] = matrix[y][x] - 1023;
      }
    }
  }

  return {
    offsetX,
    offsetY,
    matrix,
    width,
    height,
  };
};

const stripSprinklerPrecipitation = (
  { x, y, rectHeight, rectWidth, rectType, startAngle, coverage },
  precipitationByDistance
) => {
  if (coverage == null) {
    coverage = stripSprinklerCoverage({
      x,
      y,
      rectType,
      startAngle,
      rectWidth,
      rectHeight,
    });
  }
  const { width, height, offsetX, offsetY } = coverage;
  const matrix = cloneMatrix(coverage.matrix);

  const center = [x - offsetX, y - offsetY];

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      if (matrix[y][x] > 0) {
        const d = lineLength({ x, y }, { x: center[0], y: center[1] });
        matrix[y][x] = precipitationByDistance(d);
      }
    }
  }

  return {
    matrix,
    offsetX,
    offsetY,
    width,
    height,
  };
};

/**
 * Returns sprinkler precipitation mask with offsets and sizes.
 *
 * @param {*} sprinkler a sprinkler with circleRadius, precipitation, maxRadius properties.
 * Also should contain either coverage or x, y, startAngle, circleSectorAngle properties.
 * coverage can be filled using {@link #sprinklerCoverage}.
 *
 * @param {*} precipitationByDistance
 * a fn with a single parameter distanceFromCenter.
 */
const rotatorSprinklerPrecipitation = (
  { circleRadius, coverage, x, y, startAngle, circleSectorAngle },
  precipitationByDistance
) => {
  if (coverage == null) {
    coverage = rotatorSprinklerCoverage({
      x,
      y,
      circleRadius,
      startAngle,
      circleSectorAngle,
    });
  }
  const { width, height, offsetX, offsetY } = coverage;
  const matrix = cloneMatrix(coverage.matrix);

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      if (matrix[y][x] > 0) {
        const d = lineLength({ x, y }, { x: circleRadius, y: circleRadius });
        matrix[y][x] = precipitationByDistance(d);
      }
    }
  }

  return {
    matrix,
    offsetX,
    offsetY,
    width,
    height,
  };
};

const sprinklersEqual = (a, b) =>
  [
    "circleRadius",
    "circleSectorAngle",
    "startAngle",
    "x",
    "y",
    "rectWidth",
    "rectHeight",
  ].every((key) => (a[key] == null && b[key] == null) || a[key] === b[key]);

/**
 * Returns strip sprinkler corners.
 *
 * @param {*} sprinkler with x, y, rectType, startAngle, rectWidth, rectHeight properties
 */
const stripSprinklerCorners = ({
  x,
  y,
  rectType,
  startAngle,
  rectWidth,
  rectHeight,
}) => {
  let corners = [];

  switch (rectType) {
    case "left":
      corners = [
        { x, y: y - rectHeight },
        { x: x + rectWidth, y: y - rectHeight },
        { x: x + rectWidth, y: y },
        { x, y },
      ];
      break;
    case "right":
      corners = [
        { x: x - rectWidth, y: y - rectHeight },
        { x, y: y - rectHeight },
        { x, y },
        { x: x - rectWidth, y },
      ];
      break;
    case "center":
      corners = [
        { x: x - rectWidth / 2, y: y - rectHeight },
        { x: x + rectWidth / 2, y: y - rectHeight },
        { x: x + rectWidth / 2, y },
        { x: x - rectWidth / 2, y },
      ];
      break;
    default:
      throw new Error("Invalid rectType");
  }

  return corners.map((p) => rotatePoint(p, { x, y }, -startAngle));
};

/**
 *  return sprinklers distance array
 */
const sprinklersDistanceArray = (areas, sprinklers, thresholdInPx = 10) => {
  let result = [];
  areas
    .filter((a) => a.quantity === "should" || a.quantity === "can")
    .forEach((area) => {
      const areaDirection = area.pointsDirection;
      const resultByArea = [];
      for (let [start, end] of getStrightEdges(area)) {
        const direction =
          start && start.direction != null ? start.direction : -1;
        //find sprinklers by edge
        let sprinklersByEdge = sprinklers
          .map((s) => {
            let closestPoint = closestPointInLine(s, start, end, 0);
            const distance = lineLength(s, closestPoint);
            return distance <= thresholdInPx
              ? { point: closestPoint, sprinkler: s, distance }
              : null;
          })
          .filter((e) => e != null);

        //sort points
        if (sprinklersByEdge && sprinklersByEdge.length > 1) {
          sprinklersByEdge.sort((a, b) => {
            return lineLength(a.point, start) - lineLength(b.point, start);
          });

          for (let i = 1; i < sprinklersByEdge.length; i++) {
            resultByArea.push({
              start: sprinklersByEdge[i - 1].point,
              end: sprinklersByEdge[i].point,
              distance: lineLength(
                sprinklersByEdge[i - 1].point,
                sprinklersByEdge[i].point
              ),
              direction,
            });
          }
        }
      }
      result.push({ direction: areaDirection, distances: resultByArea });
    });
  return result;
};

/**
 * @param sprinklers sprinklers array
 * @param scale sprinklers image scale
 * @param distance min distance between sprinklers in zone
 * @param threshold sprinklers distance in px that can be zoomed (default 10px)
 * @param imageSize (default 500px)
 * @param otherZoneIntersectSquarePercent default 5%
 * @param zonePadding (default 20px)
 * @return zones = [{sprinkers, radius, center}...] radius - it's not cicrcle radius (width and height = 2*radius)
 */
const magnitificationSprinklers = 
  (
    sprinklers,
    scale,
    distance,
    threshold = 8,
    imageSize = 500,
    otherZoneIntersectSquarePercent = 2,
    zonePadding = 20
  ) => {
    let result = [];
    //1. prepare dataset (ignore the sprinkler {S} if distance between {S} and {P} <= threshold, where {P} - another point from dataset)
    let dataset = [];
    sprinklers.forEach((s) => {
      if (
        dataset.length === 0 ||
        dataset.find((d) => lineLength(s, { x: d[0], y: d[1] }) <= threshold) ==
          null
      ) {
        dataset.push([s.x, s.y]);
      }
    });
    //2. clustering
    const dbscan = new clustering.DBSCAN();
    let clusters = dbscan.run(dataset, distance / scale, 1);
    //3. cut clusters to multiple zone
    let zones = [];
    clusters = clusters.filter(
      (c) =>
        c.length > 1 ||
        sprinklers.filter(
          (sprinkler) =>
            lineLength(sprinkler, { x: c[0], y: c[1] }) <= threshold
        ).length > 1
    );
    clusters.forEach((cluster) => {
      let pointsWithoutZone = cluster.map((i) => dataset[i]);
      while (pointsWithoutZone.length > 0) {
        let firstPoint = pointsWithoutZone.sort((a, b) =>
          a[1] === b[1] ? a[0] - b[0] : a[1] - b[1]
        )[0];
        let newZone = {
          points: [firstPoint],
          radius: 10, //distance,
          center: { x: firstPoint[0], y: firstPoint[1] },
        };
        //remove firstPoint
        pointsWithoutZone = pointsWithoutZone.filter(
          (p) => p[0] !== firstPoint[0] || p[1] !== firstPoint[1]
        );

        let pointsAdded = true;
        while (pointsAdded) {
          let neighbors = [];
          let sortedByPrior = pointsWithoutZone.sort(
            (a, b) =>
              lineLength(newZone.center, { x: a[0], y: a[1] }) -
              lineLength(newZone.center, { x: b[0], y: b[1] })
          );

          sortedByPrior.forEach((p) => {
            //recalc radius
            const a = [...newZone.points, p];
            const minX = a
              .map((e) => e[0])
              .reduce((acc, value) => Math.min(acc, value), p[0]);
            const maxX = a
              .map((e) => e[0])
              .reduce((acc, value) => Math.max(acc, value), p[0]);
            const minY = a
              .map((e) => e[1])
              .reduce((acc, value) => Math.min(acc, value), p[1]);
            const maxY = a
              .map((e) => e[1])
              .reduce((acc, value) => Math.max(acc, value), p[1]);

            let tempScale = Math.max(maxX - minX, maxY - minY) / imageSize;
            let newRadius =
              Math.max(maxX - minX, maxY - minY) / 2 + zonePadding * tempScale;
            let newCenter = {
              x: minX + (maxX - minX) / 2,
              y: minY + (maxY - minY) / 2,
            };

            // find additional points
            let additionalPoints = pointsWithoutZone.filter(
              (ap) =>
                (p[0] !== ap[0] || p[1] !== ap[1]) &&
                ap[0] <= newCenter.x + newRadius &&
                ap[0] >= newCenter.x - newRadius &&
                ap[1] <= newCenter.y + newRadius &&
                ap[1] >= newCenter.y - newRadius
            );

            // find minDistanceByZone and scale
            const allPointsByNewZone = [...a, ...additionalPoints];
            const distances = [];
            for (let i = 0; i < allPointsByNewZone.length; i++) {
              const p1 = {
                x: allPointsByNewZone[i][0],
                y: allPointsByNewZone[i][1],
              };
              for (let j = 0; j < i; j++) {
                const p2 = {
                  x: allPointsByNewZone[j][0],
                  y: allPointsByNewZone[j][1],
                };
                distances.push(lineLength(p1, p2));
              }
            }
            const minDistanceByZone = distances.reduce(
              (acc, value) => (acc < 0 ? value : Math.min(acc, value)),
              -1
            );
            const newScaleByZone = imageSize / (2 * newRadius);

            if (
              minDistanceByZone > distance / newScaleByZone &&
              zones
                .map((z) => {
                  const l = Math.max(
                    newCenter.x - newRadius,
                    z.center.x - z.radius
                  );
                  const r = Math.min(
                    newCenter.x + newRadius,
                    z.center.x + z.radius
                  );
                  const t = Math.max(
                    newCenter.y - newRadius,
                    z.center.y - z.radius
                  );
                  const b = Math.min(
                    newCenter.y + newRadius,
                    z.center.y + z.radius
                  );
                  const zs = Math.max(r - l, 0) * Math.max(b - t, 0);
                  return zs > 0
                    ? Math.ceil((100 * zs) / (4 * z.radius * z.radius))
                    : 0;
                })
                .find((s) => s > otherZoneIntersectSquarePercent) == null
            ) {
              neighbors.push({
                points: [p, ...additionalPoints],
                newRadius,
                newCenter,
              });
            }
          });

          if (neighbors.length > 0) {
            let selectedNeighbor = neighbors.sort(
              (a, b) => a.radius - b.radius
            )[0];
            newZone.points.push(...selectedNeighbor.points);
            newZone.radius = selectedNeighbor.newRadius;
            newZone.center = selectedNeighbor.newCenter;
            pointsWithoutZone = pointsWithoutZone.filter(
              (p) =>
                newZone.points.find(
                  (nzp) => nzp[0] === p[0] && nzp[1] === p[1]
                ) == null
            );
          } else {
            pointsAdded = false;
          }
        }
        zones.push(newZone);
      }
    });
    //4. prepare result (replace points by zone to sprinklers)
    result =
      zones && zones.length > 0
        ? zones
            .map((zone) => {
              const { points, radius, center } = zone;
              const sprinklersByZone = [];
              points.forEach((p) => {
                const s = sprinklers.filter(
                  (sprinkler) =>
                    lineLength(sprinkler, { x: p[0], y: p[1] }) <= threshold
                );
                if (s && s.length > 0) {
                  sprinklersByZone.push(...s);
                }
              });
              return { sprinklers: sprinklersByZone, radius, center };
            })
            .filter((z) => z.sprinklers && z.sprinklers.length > 0)
        : [];

    return result;
  }

module.exports = {
  polygonizeRotatorSprinkler,
  sprinklerPrecipitationByDistance,
  rotatorSprinklerCoverage,
  stripSprinklerCoverage,
  rotatorSprinklerPrecipitation,
  sprinklersEqual,
  stripSprinklerPrecipitation,
  stripSprinklerCorners,
  sprinklersDistanceArray,
  magnitificationSprinklers,
};
